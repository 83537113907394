import React, { useRef, useLayoutEffect, useImperativeHandle, forwardRef } from "react";

import { setLayerSource, getImageStatic, getBaseMap, getZoneLabel } from '../../components/utils'
import '../../components/style.css'

const ZoneMap = forwardRef(({ zones, floorplans, mapExt }, ref) => {    
  const mapRef = useRef()

  useLayoutEffect(() => {
    const { labelSource, labelLayer } = getZoneLabel()

    const map = getBaseMap([labelLayer])

    mapRef.current = {
      "map": map,
      "labelSource": labelSource,
      "labelLayer": labelLayer
    }

    zoneUpdate(zones.map(x => x.content).reduce((x, y) => { return x = [...x, ...y];}))
    addFloorPlan(floorplans)
    setMapExtent(mapExt)
  }, [])

  const zoneUpdate = (data) => {
    setLayerSource(
      mapRef.current.labelLayer, 
      {
        "type": "FeatureCollection",
        "features": data.map((i) => ({
          "type": "Feature",
          "id": i["zone_id"],
          "geometry":{
            "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
          },
          "properties":{
            "label": i["zone_name"],
          }
        }))
      }
    )
  }

  const addFloorPlan = (floorplans) => {
    floorplans.forEach((i) => {
      const newLayer = getImageStatic(i["coor"]["location"], URL.createObjectURL(i["blob"]), [i["coor"]["x1"], i["coor"]["y1"] , i["coor"]["x2"], i["coor"]["y2"]])
      mapRef.current.map.addLayer(newLayer)
    })
  }

  const setMapExtent = (coordinate) => {
    mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
  }

  return (<>
    <div style={{ width: "100%", height: "100%" }} id="map" />
  </>)
})

export default ZoneMap

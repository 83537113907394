import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import moment from 'moment';
import { saveAs } from 'file-saver';

import PlaybackModal from './components/PlaybackModal';

import TimeRangePicker from 'Shared/Components/TimeRangePicker/TimeRangePicker';
import { FloatingDatePicker, FloatingDropdown } from 'Shared/Components/FloatingLabel/FloatingLabel';
import { showError } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { apiUrl, playbackUrl } from 'Shared/utils';

const CameraPlayback = () => {
  const { send } = useFetchData()

  const [cameras, setCameras] = useState([])
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState()
  const [date, setDate] = useState()

  const [error, setError] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState()
  const [timelineInterval, setTimelineInterval] = useState()
  const [disabledIntervals, setDisabledIntervals] = useState()

  const [playUrl, setPlayUrl] = useState()

  useEffect(() => {
    const init = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/camera/list`,
        returnType: "json"
      })

      setCameras(body.map((i) => ({
        value: i["camera_id"],
        label: i["name"],
      })))

      setLoading(false)
    }

    init()
  }, [])

  const onChangeCallback = (e) => {
    setError(e.error)
    e.interval && setSelectedInterval(e.interval)
  }

  const loadData = async (d, p) => {
    if (d == null || p == null) {
      return
    }

    setLoading(true)

    const res = await fetch(`${playbackUrl}/playback/list/${p}/${moment(d).format("YYYY-MM-DD")}`, {
      method: "GET"
    })

    if (res.status == 200) {
      const body = await res.json()

      setDisabledIntervals(
        body.map((i) => ({
          'start': Date.parse(i["start"]),
          'end': Date.parse(i["end"])
        }))
      )

      setSelectedInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
      setTimelineInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
    }

    setLoading(false)
  }

  const play = () => {
    if (error) {
      showError(`Error: Invalid Range`)
      return
    }

    const url = `${playbackUrl}/playback/get/${selected}/player?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`
    setPlayUrl(url)
  }

  const download = async () => {
    setLoading(true)

    if (error) {
      showError(`Error: Invalid Range`)
      setLoading(false)
      return
    }
    else if (moment(selectedInterval[1]).diff(moment(selectedInterval[0]), 'minutes', true) > 240) {
      showError(`Error: Maximum download interval: 4 hour`)
      setLoading(false)
      return
    }

    const res = await fetch(`${playbackUrl}/playback/download/${selected}?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`, {
      method: "GET"
    })

    if (res.status == 200) {
      const blob = await res.blob()

      saveAs(blob, `playback.mp4`)
    }

    setLoading(false)
  }

  const updateDate = (d) => {
    setDate(d)

    loadData(d, selected)
  }

  const updatePath = (p) => {
    setSelected(p)

    const d = new Date()

    setDate(d)
    loadData(d, p)
  }

  return (<>
    <div className="mainContainer">
      <Container className="mainContainer" fluid style={{ width: "100%", height: "100%", borderRadius: "15px", backgroundColor: "white", overflow: "auto" }}>
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={12} lg={4} className="mb-1 mt-lg-0 mb-lg-0">
            <FloatingDropdown label="Camera Name" name="name" value={selected} options={cameras} onChange={(e) => updatePath(e)} />
          </Col>

          <Col xs={12} lg={4} className="mt-1 mb-1 mt-lg-0 mb-lg-0">
            <FloatingDatePicker label="Date" name="date" value={date} onChange={(e) => updateDate(new Date(e))}/>
          </Col>

          <Col xs={12} lg={4} className="mt-1 mt-lg-0 mb-lg-0">
            {(selected != null && selectedInterval != null && disabledIntervals != null && timelineInterval != null) && <>
              <ButtonGroup style={{ width: "100%" }}>
                {(playUrl == null) ? (<>
                  <Button variant="primary" style={{width: "50%"}} onClick={() => play()}>Play</Button>
                  <Button variant="success" style={{width: "50%"}} onClick={() => download()}>Download</Button>
                </>) : (
                  <Button variant="danger" style={{width: "100%"}} onClick={() => setPlayUrl()}>Stop</Button>
                )}
              </ButtonGroup>
            </>}
          </Col>
        </Row>

        {(selected != null && selectedInterval != null && disabledIntervals != null && timelineInterval != null) && <>
          <Row>
            <Col>
              <TimeRangePicker
                error={error}
                step={60000}
                ticksNumber={120}
                selectedInterval={selectedInterval}
                timelineInterval={timelineInterval}
                disabledIntervals={disabledIntervals}
                onChangeCallback={onChangeCallback}
                setTimelineInterval={setTimelineInterval}
                min={moment(date).startOf('day').toDate()}
                max={moment(date).add(1, "days").startOf('day').toDate()}
                showNow
              />

              <div style={{ paddingLeft: "30px" }}>Selected Period: {`${moment(selectedInterval[0]).format("YYYY-MM-DD HH:mm:SS")} ~ ${moment(selectedInterval[1]).format("YYYY-MM-DD HH:mm:SS")}`}</div>
            </Col>
          </Row>

          <Row style={{ flexGrow: 1 }}>
            <Col style={{ height: "100%" }}>
              <PlaybackModal playUrl={playUrl} toggle={() => setPlayUrl()}/>
            </Col>
          </Row>
        </>}
      </Container>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default CameraPlayback
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'
import moment from 'moment';

import { FaImage } from "react-icons/fa6";

import useFetchData from 'Shared/Hooks/useFetchData'
import { apiUrl } from 'Shared/utils'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';

const ProjectSystemLog = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [activityLog, setActivityLog] = useState({})
  const [loading, setLoading] = useState(true)

  const [roiActivityLogImage, setroiActivityLogImage] = useState()

  const tableRef = useRef()

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/common/activity_log`,
      params: {
        "offset": (tableRef.current.getState()["page"]-1)*tableRef.current.getState()["itemsPerPage"],
        "limit": tableRef.current.getState()["itemsPerPage"]
      },
      returnType: "json"
    })

    const getAction = (item) => {
      switch (item["action"]) {
        case "change_camera_roi":
          return <div>{t("activityLog_changeCameraRoi")}</div>
        case "change_smartwatch_ownership":
          return <div>{t("activityLog_changeSmartwatchOwnership")}</div>
        default:
          return null
      }
    }
    
    const getDescription = (item) => {
      switch (item["action"]) {
        // case "change_camera_roi":
        //   return <div>Change camera  detection ROI</div>
        case "change_smartwatch_ownership":
          return <div>Change assignment from <u><b>{item["original_value"]}</b></u> to <u><b>{item["new_value"]}</b></u></div>
        default:
          return null
      }
    }

    setActivityLog({
      "data": body["data"].map((i) => ({
        "record_id": i["record_id"],
        "action_type": i["action"],
        "datetime": moment(new Date(i["datetime"])).format("YYYY-MM-DD HH:mm:SS"), 
        "action": getAction(i),
        "target": i["target"],
        "description": getDescription(i),
        "performed_by": i["performed_by"],
      })),
      "count": Math.ceil(body["count"]/tableRef.current.getState()["itemsPerPage"])
    })
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  const showChangeLog = (row) => {
    switch(row["action_type"]) {
      case "change_camera_roi":
        return [
          {icon: <FaImage size={20}/>, text: t("activityLog_showROIChange"), onClick: function(row) { setroiActivityLogImage(row["record_id"]) }},
        ]
      default:
        return []
    }
  }

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={activityLog["data"]} count={activityLog["count"]} ref={tableRef}
              columns={{
                "datetime": { "label": t("activityLog_datetime"), "sorting": false, "nowrap": true },
                "action": { "label": t("activityLog_action"), "sorting": false, "nowrap": true },
                "target": { "label": t("activityLog_target"), "sorting": false, "nowrap": true },
                "description": { "label": t("activityLog_description"), "sorting": false, "nowrap": true },
                "performed_by": { "label": t("activityLog_performedBy"), "sorting": false, "nowrap": true },
              }}
              renderActions={(row) => { return showChangeLog(row) }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={roiActivityLogImage != null} height="80%" width="80%" close toggle={() => setroiActivityLogImage()}>
      <div style={{ width: "100%", height: "100%", padding: "5px", overflowX: "hidden", overflowY: "hidden" }}>
        <iframe src={`${apiUrl}/templates/roi_activity_log.html?record_id=${roiActivityLogImage}`} style={{ width: "100%", height: "100%" }}/>
      </div>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default ProjectSystemLog

import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Stack, CircularProgress, Box, TableSortLabel, Checkbox, Divider, Collapse } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Input, Select } from 'antd';

import { IconButtonComponent } from './Icon';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontSize: 14,
		fontWeight: 'bold'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
    // borderBottom: "none"
	},
}));

const DataTable = forwardRef(({ columns, subColumns, data, count, renderActions, renderSubActions, loadData, appActions, selectActions, onRowClick, showCheck, onSearch, searchPlaceHolder }, ref) => {
  const tableStateRef = useRef({
    "order": true,
    "orderBy": "",
    "page": 1,
    "itemsPerPage": 20,
    "search": "",
    "selected": {},
    "expand": null
  })

	const [tableState, setTableState] = useState(tableStateRef.current)
	const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
		getState: () => {
			return tableStateRef.current
		},
    setLoading: (value) => {
			setLoading(value)
		},
		setPage: (page) => {
			tableStateRef.current.page = page
      setTableState(prev => ({ ...prev, page: page }))

		},
		setSelect: (value) => {
			tableStateRef.current.selected = value
      setTableState(prev => ({ ...prev, selected: value }))
		}
	}))

	const sort = (id) => {
		if (tableStateRef.current.orderBy === id) {
      tableStateRef.current.order = !tableStateRef.current.order
		}
		else {
			tableStateRef.current.order = true
			tableStateRef.current.orderBy = id
		}

    setTableState(prev => ({ ...prev,
      order: tableStateRef.current.order,
      orderBy: tableStateRef.current.orderBy
    }))

		loadData()
	}

  const handleSearch = (value, _e, info) => {
    setTableState(prev => ({ ...prev, search: value }))

    onSearch(value)
  }

  const handlePageChange = (_, newPage) =>{
		tableStateRef.current.page = newPage
    setTableState(prev => ({ ...prev, page: newPage }))

		loadData()
	}

  const pageSizeChange = (value) => {
    tableStateRef.current.itemsPerPage = value
    tableStateRef.current.page = 1

    setTableState(prev => ({ ...prev,
      itemsPerPage: value,
      page: 1
    }))

    loadData()
  }

  const onSelect = (target, value) => {
    tableStateRef.current.selected[target] = value
    setTableState(prev => ({ ...prev, selected: tableStateRef.current.selected }))
  }

  const onSelectAll = (value) => {  
    tableStateRef.current.selected = Object.fromEntries(new Map(Object.keys(tableStateRef.current.selected).map((i) => [i, value])))
    setTableState(prev => ({ ...prev, selected: tableStateRef.current.selected }))
  }

  const onExpandClicked = (id) => {
    setTableState(prev => ({ ...prev, expand: prev["expand"] != id ? id : null }))
  }

  return (<>
    {(loading || data == null) ? (
      <Box className="center">
        <CircularProgress />
      </Box>
    ) : (<>
      {(onSearch != null || appActions != null || selectActions != null) && 
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "10px" }}>
          <div>
            {onSearch &&
              <div style={{ marginLeft: "10px" }}>
                <Input.Search
                  placeholder={searchPlaceHolder}
                  defaultValue={tableState["search"]}
                  allowClear
                  onSearch={handleSearch}
                  style={{ width: 200 }}
                />
              </div>
            }
          </div>

          <div style={{ display: "flex", flexDirection: "row", paddingRight: "20px" }}>
            <Stack direction="row" spacing={0}>
              {tableState["selected"] && <>
                {(Object.values(tableState["selected"]).includes(true) && selectActions) && <>
                  {selectActions().map((action) => 
                    <IconButtonComponent title={action.text} onClick={() => action.onClick()}>
                      {action.icon}
                    </IconButtonComponent>
                  )}
                </>}
              </>}

              {appActions && <>
                {appActions().map((action) => 
                  <IconButtonComponent title={action.text} onClick={() => action.onClick()}>
                    {action.icon}
                  </IconButtonComponent>
                )}
              </>}
            </Stack>
          </div>
        </div>
      }
      
      <TableContainer sx={{ flex: 1, overflow: "auto"  }} className="scollbar">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {subColumns && 
                <StyledTableCell sx={{ width : "50px" }}>{''}</StyledTableCell>
              }

              {showCheck && 
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={(e) => onSelectAll(e.target.checked)}
                    checked={data.length > 0 && !Object.values(tableState["selected"]).includes(false)}
                  />
                </StyledTableCell>
              }
              
              {Object.entries(columns).map((i) => <> 
                {i[1]["sorting"] ? (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center" key={i[0]} sortDirection={tableState.orderBy === i[0] ? (tableState.order ? 'asc' : 'desc') : false}>
                    <TableSortLabel active={tableState.orderBy === i[0]} direction={tableState.order ? 'asc' : 'desc'} onClick={() => sort(i[0])}>
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>
                      
                      {tableState.orderBy === i[0] ? (
                        <Box component="span" sx={visuallyHidden}>
                          {tableState.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center">
                    <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>      
                  </StyledTableCell>
                )}
              </>)}

              {renderActions && 
                <StyledTableCell align="center">{''}</StyledTableCell>
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, i) => {
              const actions = renderActions ? renderActions(row) : null
              const expand = (tableState["expand"] === row["id"])

              return (<>
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: onRowClick ? "pointer": "auto" }} hover={onRowClick != null} onClick={(e) => onRowClick && onRowClick(e, row)}>
                  {subColumns && 
                    <StyledTableCell sx={{ borderBottom: expand ? "none": "" }}>
                      <IconButtonComponent onClick={() => onExpandClicked(row["id"])} > 
                        {expand ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                      </IconButtonComponent>
                    </StyledTableCell>
                  }
                  
                  {showCheck && 
                    <StyledTableCell sx={{ borderBottom: expand ? "none": "" }} padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={(e) => onSelect(row.id, e.target.checked)}
                        checked={tableState["selected"][row.id]}
                      />
                    </StyledTableCell>
                  }
              
                  {Object.entries(columns).map((j) =>
                    <StyledTableCell sx={{ borderBottom: expand ? "none": "" }} align="center" key={j[0]}>
                      {j[1]["nowrap"] ? (
                        <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{(row[j[0]] !== "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                      ) : (
                        <div>{(row[j[0]] !== "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                      )}
                    </StyledTableCell>
                  )}

                  {actions &&
                    <StyledTableCell align="center" sx={{ borderBottom: expand ? "none": "", width : `${actions.length*50}px` }} key="actionBtn">
                      <Stack justifyContent="center" direction="row" spacing={0} >
                        {actions.map((action) => 
                          <IconButtonComponent title={action.text} onClick={() => action.onClick(row)}>
                            {action.icon}
                          </IconButtonComponent>
                        )}
                      </Stack>
                    </StyledTableCell>
                  } 
                </TableRow>

                {subColumns && 
                  <TableRow>
                    <StyledTableCell sx={{ borderBottom: !expand ? "none": "", paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
                      <Collapse in={tableState["expand"] === row["id"]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                {Object.entries(subColumns).map((i) => <> 
                                  <StyledTableCell align="center">
                                    <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>      
                                  </StyledTableCell>
                                </>)}

                                {renderSubActions && 
                                  <StyledTableCell align="center">{''}</StyledTableCell>
                                }
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {row["content"].map((row2, i2) => {
                                const actions = renderSubActions ? renderSubActions(row) : null

                                return (
                                  <TableRow key={i2} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: onRowClick ? "pointer": "auto" }} hover={onRowClick != null} onClick={(e) => onRowClick && onRowClick(e, row)}>
                                    {Object.entries(subColumns).map((j2) =>
                                      <StyledTableCell align="center" key={j2[0]}>
                                        <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{(row2[j2[0]] !== "" && row2[j2[0]] != null) ? row2[j2[0]] : "-"}</div>
                                      </StyledTableCell>
                                    )}

                                    {actions &&
                                      <StyledTableCell align="center" sx={{ width : `${actions.length*50}px` }} key="actionBtn">
                                        <Stack justifyContent="center" direction="row" spacing={0} >
                                          {actions.map((action) => 
                                            <IconButtonComponent title={action.text} onClick={() => action.onClick(row, row2)}>
                                              {action.icon}
                                            </IconButtonComponent>
                                          )}
                                        </Stack>
                                      </StyledTableCell>
                                    } 
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </StyledTableCell>
                  </TableRow>
                }
              </>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>)}

    {(loading == false) && <>
      {(count != null && count > 0) && <>
        <Divider sx={{ bgcolor: "secondary.light" }}/>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "15px", paddingRight: "15px", paddingTop: "25px", paddingBottom: "25px", height: "40px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>Rows per Page: </div>

            <div style={{ marginLeft: "10px" }}>
              <Select
                value={tableState["itemsPerPage"]}
                onChange={(e) => pageSizeChange(e)}
                options={[
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ]}
              />
            </div>
          </div>
          
          <Pagination size="small" page={tableState["page"]} count={count} siblingCount={2} boundaryCount={0} onChange={handlePageChange}/>
        </div>
      </>}
    </>}
  </>)
})

export default DataTable

import React, { useRef, useState, useEffect, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Map, View, Overlay } from "ol";
import { XYZ, Vector as VectorSource, OSM } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {  Icon, Fill, Text, Style, Circle as CircleStyle, Stroke } from "ol/style";
import { GeoJSON } from "ol/format";
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import { Divider, Paper, Stack } from '@mui/material';

import { IoIosClose } from "react-icons/io";

import { webUrl } from "Shared/utils";
import useLoop from "Shared/Hooks/useLoop";
import useAuth from "Shared/Hooks/useAuth";

import AlertIcons from "../../components/AlertIcons";
import { getIconStyle, getLabelStyle, setLayerSource, getBaseMap } from '../../components/utils'
import '../../components/style.css'

const MapDisplay = forwardRef(({ focus }, ref) => {
  const { t } = useTranslation()

  const [dimension, setDimension] = useState({ "width": 0, "height": 0 })
  const [opened, setOpened] = useState()
  const mapRef = useRef()
  const divRef = useRef()
  const { role } = useAuth()

  useEffect(() => {
    const checkSize = () => {
      setDimension({
        "width": divRef.current.offsetWidth,
        "height": divRef.current.offsetHeight
      })
    };

    if (!divRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      checkSize()
    });

    resizeObserver.observe(divRef.current);
    
    return () => resizeObserver.disconnect();
  }, []);

  useLayoutEffect(() => {
    const workerLayer = new VectorLayer({
      source: new VectorSource({}),
      style: function (feature) { 
        const online = feature.get("online")
        const warning = Object.entries(feature.get("alerts")).filter(([k, v]) => v === true).length > 0

        const zIndex = online ? (warning ? 100 : 10) : 1
        const icon = online ? (warning ? "worker_warning" : "worker_normal") : "worker_offline"

        return [
          getIconStyle(icon, 30, zIndex)
        ]
      }
    })

    workerLayer.set('name', 'workerLayer')

    const dangerZoneLayer = new VectorLayer({
      source: new VectorSource({}),
      style: function (feature) {
        return new Style({
          stroke: new Stroke({ color: 'red', width: 3 }),
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.1)' })
        })
      }
    })

    dangerZoneLayer.set('name', 'dangerZoneLayer')

    const map = getBaseMap([dangerZoneLayer, workerLayer])

    map.on('click', function (e) {
      const feature_id = map.forEachFeatureAtPixel(e.pixel, function (f) {
        return f.getId()
      } , {
        layerFilter: function (layer) {
          return layer.get('name') === 'workerLayer'
        }
      })

      if (feature_id == null) {
        resetPopup()
        return
      }

      focus(feature_id, false)
    })

    mapRef.current = {
      "map": map,
      "workerLayer": workerLayer,
      "dangerZoneLayer": dangerZoneLayer,
    }
  }, [])

  const sosAnimation = () => {
    function flash(feature) {
      const map = mapRef.current.map
      let tileLayer = map.getLayers().getArray()[0]
  
      const duration = 3000
  
      const start = Date.now()
      const flashGeom = feature.getGeometry().clone()
      const listenerKey = tileLayer.on('postrender', animate)
    
      function animate(event) {
        const frameState = event.frameState
        const elapsed = frameState.time - start
        if (elapsed >= duration) {
          unByKey(listenerKey)
          return
        }

        const vectorContext = getVectorContext(event)
        const elapsedRatio = elapsed / duration
        const radius = easeOut(elapsedRatio) * 25 + 5
        const opacity = easeOut(1 - elapsedRatio)
    
        const style = new Style({
          image: new CircleStyle({
            radius: radius,
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, ' + opacity + ')',
              width: 0.25 + opacity,
            }),
          }),
        })
    
        vectorContext.setStyle(style)
        vectorContext.drawGeometry(flashGeom)

        map.render()
      }
    }
    
    const map = mapRef.current.map
    const features = mapRef.current.workerLayer.getSource().getFeatures()

    features.forEach((f) => {
      if (f.get("online") && f.get("alerts")["sos"]) {
        flash(f)
      }

      map.render()
    })
  }

  const zoomToFeature = (id, feature, zoom) => {
    if (mapRef.current.selected != id) {
      const map = mapRef.current.map

      let bodyTemp = feature.get("body_temp")
      let heartRate = feature.get("heart_rate")

      setOpened({
        "worker_name": feature.get("worker_name"),
        "battery_level": feature.get("battery_level"),
        "body_temp": bodyTemp != null ? (role === 'pm' || role === 'admin') ? bodyTemp : t(`smartwatch_bodyTemp_${bodyTemp}`) : null,
        "heart_rate": heartRate != null ? (role === 'pm' || role === 'admin') ? heartRate : t(`smartwatch_heartRate_${heartRate}`) : null,
        "online": feature.get("online"),
        "alerts": feature.get("alerts")
      })

      if ((feature.get("lon") == 0 || feature.get("lon") == null) || feature.get("lat") == 0 || feature.get("lat") == null) {      
        resetPopup()
        return
      }

      const coor = feature.getGeometry().getCoordinates()

      if (zoom) {
        const view = map.getView()

        view.animate({
          center: coor,
          duration: 2000,
          zoom: 20
        });
      }
    }
  }

  const resetPopup = () => {
    setOpened()
  }

  useLoop(sosAnimation, 2000)

  useImperativeHandle(ref, () => ({
    getFeature: (id) => {      
      return mapRef.current.workerLayer.getSource().getFeatureById(id)
    },
    zoomToFeature: (id, feature, content, zoom) => {
      zoomToFeature(id, feature, content, zoom)
    },
    updateData: (data) => {
      const map = mapRef.current.map

      if (map) {
        setLayerSource(mapRef.current.workerLayer, data)
      }
    },
    updateZone: (type, zone) => {
      const map = mapRef.current.map

      if (map) {
        switch (type) {
          case "danger_zone":
            mapRef.current.dangerZoneLayer.setStyle(new Style({
              stroke: new Stroke({ color: 'rgb(255, 0, 0)', width: 3 }),
              fill: new Fill({ color: 'rgba(0, 255, 0, 0.1)' })
            }));

            break
          case "working_zone":
            mapRef.current.dangerZoneLayer.setStyle(new Style({
              stroke: new Stroke({ color: 'rgb(0, 255, 0)', width: 3 }),
              fill: new Fill({ color: 'rgba(0, 255, 0, 0.1)' })
            }));

            break

          default:
            break
        }

        setLayerSource(mapRef.current.dangerZoneLayer, zone)
      }
    },
    closePopup: () => {
      resetPopup()
    },
    setMapExtent: (coordinate) => {
      mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"], coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
    }
  }))

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ref={divRef}>
      <div style={{ height: "100%", width: "100%" }} id="map" />

      {(opened != null) && 
        <div style={{ height: dimension["height"]*0.8, width: dimension["width"]*0.4, position: "absolute", right: 30 }}>
          <Paper elevation={3} style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IoIosClose onClick={() => resetPopup()}/>
            </div>

            <div>
              <div style={{ fontSize: "12px" }}>{t("smartwatch_worker")}: {opened["worker_name"]}</div>
              <div style={{ fontSize: "12px" }}>{t("smartwatch_betteryLevel")}: {opened["battery_level"]}</div>
              <div style={{ fontSize: "12px" }}>{t("smartwatch_bodyTemp")}: {opened["body_temp"]}</div>
              <div style={{ fontSize: "12px" }}>{t("smartwatch_heartRate")}: {opened["heart_rate"]}</div>
              <div style={{ width: "fit-content" }}>
                {((Object.entries(opened["alerts"]).filter(([k, v]) => v === true).length > 0 === true) && opened["online"] === true) &&
                  <AlertIcons data={opened["alerts"]} />
                }
              </div>
            </div>
          </Paper>
        </div>
      }
    </div>
  )
})

export default MapDisplay

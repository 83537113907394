import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Paper, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import { TbArrowsMinimize, TbArrowsMaximize } from "react-icons/tb";
import { transform } from 'ol/proj';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import { IconButtonComponent } from 'Shared/Components/Icon';
import useAuth from 'Shared/Hooks/useAuth';
import MapEdit from './components/MapEdit';
import { IoAddCircleOutline, IoRemoveCircle } from 'react-icons/io5';

const SmartwatchOutdoorGeofencing = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { role } = useAuth()
  const mapRef = useRef()

  const [mapExt, setMapExt] = useState()
  const [zones, setZones] = useState();
  const [modifing, setModifing] = useState(false);
  const [mode, setMode] = useState(0);
  const [mapLoading, setMapLodaing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState();

  const fetchGeofencingZones = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/geofencing_zones`,
      returnType: "json"
    })

    if (body["zone_type"] != null) {
      setZones({
        "zone_type": body["zone_type"],
        "zones": body["zones"]
      })
    }
    else {
      setZones({
        "zone_type": "working_zone",
        "zones": body["zones"]
      })
    }
  }

  const fetchMapExtent = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/map_extent`,
      returnType: "json"
    })

    if (body != null) {
      setMapExt(body)
    }
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        fetchMapExtent(),
        fetchGeofencingZones()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  const modeUpdate = (mode, editing) => {
    setMapLodaing(true)

    setTimeout(() => {
      setMode(mode)
      setModifing(mode != 0)

      setMapLodaing(false)
    }, 1000);
  }

  const onDrawStart = () => {
    modeUpdate(1)
  }

  const onReset = () => {
    modeUpdate(0)
  }

  const onSumit = async () => {
    let coor

    switch(mode) {
      case 1:
        coor = mapRef.current.getDrawFeature()

        setLoading(true)

        const res1 = await send({
          method: "POST",
          url: `${apiUrl}/smartwatch/geofencing_zones`,
          contentType: "json",
          body: {
            "area": JSON.stringify(coor),
          }
        })

        if (res1 != null) {
          showSuccess(t("general_success"))
    
          await Promise.all([
            fetchMapExtent(),
            fetchGeofencingZones()
          ]);
        }
    
        setLoading(false)
        modeUpdate(0)

        return

      case 2:
        coor = mapRef.current.getDrawFeature()

        setLoading(true)

        const res2 = await send({
          method: "PUT",
          url: `${apiUrl}/smartwatch/geofencing_zones/${selected}`,
          contentType: "json",
          body: {
            "area": JSON.stringify(coor),
          }
        })

        if (res2 != null) {
          showSuccess(t("general_success"))
    
          await Promise.all([
            fetchMapExtent(),
            fetchGeofencingZones()
          ]);
        }
    
        setLoading(false)
        modeUpdate(0)

        return

      default:
        return
    }
  }

  const onSelect = (id) => {
    if (id != null) {
      setSelected(id)
    }
    else {
      setSelected()
    }
  }

  const onRemove = async () => {
    setLoading(true)

    const res3 = await send({
      method: "DELETE",
      url: `${apiUrl}/smartwatch/geofencing_zones/${selected}`,
      contentType: "json",
    })

    if (res3 != null) {
      showSuccess(t("general_success"))

      await Promise.all([
        fetchMapExtent(),
        fetchGeofencingZones()
      ]);
    }

    setLoading(false)
    modeUpdate(0)
    setSelected()

    return
  }

  const onEditStart = async () => {
    modeUpdate(2)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row>
        <Col xs={12} style={{ paddingLeft: 0, paddingRight: 0}}>
          {(role === "admin") &&
            <div style={{ display: "flex", justifyContent: "end", padding: "5px 10px 5px 5px" }}>
              {(modifing == true) ? (
                <Stack direction="row">
                  <IconButtonComponent title={t("general_sumit")} onClick={() => onSumit()}>
                    <FaCheck size={20}/>
                  </IconButtonComponent>

                  <IconButtonComponent title={t("general_reset")} onClick={() => onReset()}>
                    <FaTimes size={20}/>
                  </IconButtonComponent>
                </Stack>
              ) : (<>
                {(selected != null) ? (
                  <Stack direction="row">
                    <IconButtonComponent title={t("general_edit")} onClick={() => onEditStart()}>
                      <FaEdit size={20}/>
                    </IconButtonComponent>
                    <IconButtonComponent title={t("general_remove")} onClick={() => onRemove()}>
                      <IoRemoveCircle size={20}/>
                    </IconButtonComponent>
                  </Stack>
                ) : (
                  <Stack direction="row">
                    <IconButtonComponent title={t("general_add")} onClick={() => onDrawStart()}>
                      <IoAddCircleOutline size={20}/>
                    </IconButtonComponent>
                  </Stack>
                )}
              </>)}
            </div>
          }
        </Col>
      </Row>

      <Row style={{ flexGrow: 1, marginBottom: "10px" }}>
        <Col xs={12}>
          {(mapLoading === false && zones && mapExt) ? (
            <MapEdit ref={mapRef} zones={zones} mapExt={mapExt} mode={mode} selected={selected} onSelect={onSelect}/>
          ) : (
            <Box className="center">
              <CircularProgress />
            </Box>
          )}
        </Col>
      </Row>          
    </Container>

    <LoadingScreen isOpen={loading || mapLoading} />
  </>)
}

export default SmartwatchOutdoorGeofencing

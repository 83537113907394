import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Container, Col, Row } from 'react-bootstrap'
import moment from 'moment';

import useFetchData from 'Shared/Hooks/useFetchData'
import { apiUrl } from 'Shared/utils'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const SystemLog = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()

  const [activityLog, setActivityLog] = useState([])
  const [loading, setLoading] = useState(true)

  const tableRef = useRef()

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/user/activity_log`,
      params: {
        "offset": (tableRef.current.getState()["page"]-1)*tableRef.current.getState()["itemsPerPage"],
        "limit": tableRef.current.getState()["itemsPerPage"]
      },
      returnType: "json"
    })

    const getAction = (item) => {
      switch (item["action"]) {
        case "add_orginization_member":
          return <div>{t("activityLog_addOrginizationMember")}</div>
        case "remove_orginization_member":
          return <div>{t("activityLog_removeOrginizationMember")}</div>
        case "grant_project_permission":
          return <div>{t("activityLog_grantUserPrmission")}</div>
        case "change_project_permission":
          return <div>{t("activityLog_changeUserPrmission")}</div>
        case "remove_project_permission":
          return <div>{t("activityLog_removeUserPrmission")}</div>
        default:
          return ""
      }
    }

    const getDescription = (item) => {
      switch (item["action"]) {
        case "add_orginization_member":
          return <div>Add user to orginization</div>
        case "remove_orginization_member":
          return <div>Remove user from orginization</div>
        case "grant_project_permission":
          return <div>Grant role <u><b>{t(`users_role_${item["new_role"]}`)}</b></u> under project <u><b>{item["project"][i18n.language.toLowerCase()]}</b></u></div>
        case "change_project_permission":
          return <div>Change role from <u><b>{t(`users_role_${item["original_role"]}`)}</b></u> to <u><b>{t(`users_role_${item["new_role"]}`)}</b></u> under project <u><b>{item["project"][i18n.language.toLowerCase()]}</b></u></div>
        case "remove_project_permission":
          return <div>Remove role of <u><b>{t(`users_role_${item["original_role"]}`)}</b></u> under project <u><b>{item["project"][i18n.language.toLowerCase()]}</b></u></div>
        default:
          return ""
      }
    }

    setActivityLog({
      "data": body["data"].map((i) => ({
        "datetime": moment(new Date(i["datetime"])).format("YYYY-MM-DD HH:mm:SS"), 
        "action": getAction(i),
        "affectedUser": i["target"],
        "description": getDescription(i),
        "performed_by": i["performed_by"],
      })),
      "count": Math.ceil(body["count"]/tableRef.current.getState()["itemsPerPage"])
    })
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    init()
  }, [])

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={activityLog["data"]} count={activityLog["count"]} ref={tableRef}
              columns={{
                "datetime": { "label": t("activityLog_datetime"), "sorting": false, "nowrap": true },
                "action": { "label": t("activityLog_action"), "sorting": false, "nowrap": true },
                "affectedUser": { "label": t("activityLog_affectedUser"), "sorting": false, "nowrap": true },
                "description": { "label": t("activityLog_description"), "sorting": false, "nowrap": true },
                "performed_by": { "label": t("activityLog_performedBy"), "sorting": false, "nowrap": true },
              }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SystemLog
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaCheck } from "react-icons/fa";
import { GrPowerReset, GrClearOption } from "react-icons/gr";

import RoiDrawCanvas from './RoiDrawCanvas';

import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { showError, showSuccess } from 'Shared/Components/NotifyToast';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import { Stack } from '@mui/material';
import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal'

const RoiDrawModal = ({ camera_id, toggle }) => {
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [image, setImage] = useState()
  const [roi, setRoi] = useState()
  const [finished, setFinished] = useState(false)
  const [roiModalOpen, setRoiModalOpen] = useState();
  const [loading, setLoading] = useState(true);

  const mapRef = useRef()

  useEffect(() => {
    const getRoi = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/camera/roi`,
        params: {
          "camera_id": camera_id
        },
        returnType: "json"
      })

      setRoi(body)
    }

    const getSnapshot = async () => {
      setLoading(true)

      const blob = await send({
        method: "GET",
        url: `${apiUrl}/camera/snapshot`,
        params: {
          "camera_id": camera_id
        },
        returnType: "blob"
      })
  
      if (blob == null) {
        showError(t("cameras_errorConnectingToCamera"))
        setLoading(false)
        return
      }
  
      const bmp = await createImageBitmap(blob);
      const { width, height } = bmp;
  
      setImage({
        "blob": blob,
        "width": width,
        "height": height
      })

      setRoiModalOpen(true)
      setLoading(false)
    }

    const init = async () => {
      await Promise.all([
        getSnapshot(),
        getRoi()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  const reset = () => {
    mapRef.current.reset()
  }

  const clear = () => {
    mapRef.current.clear()
  }

  const update = async () => {
    setLoading(true)

    let area = await mapRef.current.getCoordinates()
    area = area.map((i) => [parseFloat(i[0].toFixed(5)), parseFloat(i[1].toFixed(5))])

    let form_data = new FormData();
    form_data.append("roi", JSON.stringify(area));
    form_data.append("snapshot", image["blob"]);

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/camera/roi/${camera_id}`,
      contentType: "formdata",
      body: form_data
    })

    if (res != null) {
      showSuccess(t("general_success"))
      toggle()
    }

    setLoading(false)
  }

  return (<>
    <PopupModal isOpen={roiModalOpen} close toggle={() => toggle()} width="80%" height="80%">
      <div style={{ height: "100%", width: "100%", display: "flex", flexFlow: "column" }}>    
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          {image && 
            <RoiDrawCanvas
              mode={(roi.length > 0) ? "Modify" : "Draw"} ref={mapRef}
              blob={image["blob"]} width={image["width"]} height={image["height"]}
              area={roi ?? []} setFinishState={(finished) => setFinished(finished)}
            />
          }
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div></div>
          
          <Stack direction="row" spacing={2}>
            <IconButtonComponent title={t("camera_roiApply")} onClick={() => update()}>
              <FaCheck />
            </IconButtonComponent>

            <IconButtonComponent title={t("camera_roiReset")} onClick={() => reset()}>
              <GrPowerReset />
            </IconButtonComponent>

            <IconButtonComponent title={t("camera_roiClear")} onClick={() => clear()}>
              <GrClearOption />
            </IconButtonComponent>
          </Stack>
        </div>
      </div>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default RoiDrawModal
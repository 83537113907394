import React, { useRef, useLayoutEffect, useEffect, useImperativeHandle, forwardRef, useState } from "react";
import { ImageStatic } from "ol/source";
import { Image } from "ol/layer";
import { useTranslation } from 'react-i18next';
import { IoIosClose } from "react-icons/io";
import { Divider, Paper, Stack } from '@mui/material';
import _ from 'lodash';

import useAuth from "Shared/Hooks/useAuth";

import AlertIcons from "../../components/AlertIcons";
import { setLayerSource, getBaseMap, getZoneLabel } from '../../components/utils'
import '../../components/style.css'

const FloorplanDisplay = forwardRef(({ }, ref) => {
  const { t } = useTranslation()
  
  const [dimension, setDimension] = useState({ "width": 0, "height": 0 })
  const [opened, setOpened] = useState({})
  const mapRef = useRef()
  const divRef = useRef()
  const { role } = useAuth()

  const show = (feature) => {    
    setOpened({
      "zone_id": feature["zone_id"],
      "zone_name": feature["zone_name"],
      "zone_description": feature["zone_description"],
      "workers": feature["workers"]
    })
  }

  useEffect(() => {
    const checkSize = () => {
      setDimension({
        "width": divRef.current.offsetWidth,
        "height": divRef.current.offsetHeight
      })
    };

    if (!divRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      checkSize()
    });

    resizeObserver.observe(divRef.current);
    
    return () => resizeObserver.disconnect();
  }, []);

  useLayoutEffect(() => {
    const {labelSource, labelLayer} = getZoneLabel()
 
    const map = getBaseMap([labelLayer])

    map.on('click', function (e) {
      const feature = map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (f.getId() == null) {
          return null
        }

        return {
          "zone_id": f.getId(),
          "zone_name": f.get("zone_name"),
          "zone_description": f.get("zone_description"),
          "workers": f.get("workers")
        }
      } , {
        layerFilter: function (layer) {
          return layer.get('name') === 'labelLayer'
        }
      })

      if (feature != null) {
        show(feature)
      }
    })

    mapRef.current = {
      "map": map,
      "labelLayer": labelLayer
    }
  }, [])

  useImperativeHandle(ref, () => ({
    zoneUpdate: (data) => {
      setLayerSource(
        mapRef.current.labelLayer, 
        {
          "type": "FeatureCollection",
          "features": data.map((i) => ({
            "type": "Feature",
            "id": i["zone_id"],
            "geometry":{
              "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
            },
            "properties":{
              "zone_name": i["zone_name"],
              "zone_description": i["zone_description"],
              "workers": i["workers"],
              "label": `${i["zone_name"]} [${i["workers"].length}]`
            }
          }))
        }
      )

      if (opened["zone_id"]) {        
        setOpened({
          "zone_id": opened["zone_id"],
          "zone_name": opened["zone_name"],
          "zone_description": opened["zone_description"],
          "workers": data.filter((i) => i["zone_id"] == opened["zone_id"])[0]["workers"]
        })
      }
    },
    addFloorPlan: (blob, coordinate) => {
      mapRef.current.map.addLayer(new Image({
        source: new ImageStatic({
          url: URL.createObjectURL(blob),
          imageExtent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
        })
      }))
    },
    focus: (coordinate) => {
      mapRef.current.map.getView().fit([coordinate["x1"]-5, coordinate["y1"]-5 , coordinate["x2"]+5, coordinate["y2"]+5], mapRef.current.map.getSize())
    },
    setMapExtent: (coordinate) => {
      mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ref={divRef}>
      <div style={{ height: "100%", width: "100%" }} id="map" />
      {(opened["zone_id"] != null) && 
        <div style={{ height: dimension["height"]*0.8, width: dimension["width"]*0.4, position: "absolute", right: 30 }}>
          <Paper elevation={3} style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IoIosClose onClick={() => setOpened({})}/>
            </div>

            <Stack spacing={2} style={{ overflow: "auto" }}>
              <div>
                <div style={{ fontSize: "12px" }}>{t("smartwatch_zoneName")}: {opened["zone_name"]}</div>
                <div style={{ fontSize: "12px" }}>{t("smartwatch_zoneDescription")}: {opened["zone_description"]}</div>
              </div>

              {(opened["workers"].length > 0) && <>
                <Divider />

                <div>
                  <Stack spacing={2}  divider={<Divider orientation="orizontal" flexItem />}>
                    {opened["workers"].map((worker) => <div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_worker")}: {worker["worker_name"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_betteryLevel")}: {worker["battery_level"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_bodyTemp")}: {worker["body_temp"] != null ? (role === 'pm' || role === 'admin') ? worker["body_temp"] : t(`smartwatch_bodyTemp_${worker["body_temp"]}`) : null}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_heartRate")}: {worker["heart_rate"] != null ? (role === 'pm' || role === 'admin') ? worker["heart_rate"] : t(`smartwatch_heartRate_${worker["heart_rate"]}`) : null}</div>
                      <div style={{ width: "fit-content" }}>
                        {(Object.entries(worker["alerts"]).filter(([k, v]) => v === true).length > 0 === true) && 
                          <AlertIcons data={worker["alerts"]} />
                        }
                      </div>
                    </div>)}
                  </Stack>
                </div>
              </>}
            </Stack>
          </Paper>
        </div>
      }
    </div>
  </>)
})

export default FloorplanDisplay

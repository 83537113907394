import React, { useRef, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { Map, View } from "ol";
import { XYZ, Vector as VectorSource, ImageStatic } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer";
import {  Fill, Text, Style, RegularShape, Stroke } from "ol/style";
import { Modify } from 'ol/interaction.js'
import Transform from "ol-ext/interaction/Transform";
import { always } from 'ol/events/condition';
import { transform } from 'ol/proj';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { setLayerSource, getImageStatic, getMapTile, getMapView, getBaseMap, getZoneLabel } from '../../components/utils'
import '../../components/style.css'

const ZoneMapEdit = forwardRef(({ modifying, zones, floorplans, mapExt }, ref) => {  

  const mapRef = useRef()

  useLayoutEffect(() => {
    const { labelSource, labelLayer } = getZoneLabel()

    const rect = new VectorLayer({
      source: new VectorSource({}),
      style: new Style({
        image: new RegularShape({
          fill: new Fill({ color: [0,0,255,0]}),
          stroke: new Stroke({color: [0,0,255,1],width: 1}),
          radius: 10,
          points: 3,
        }),
        fill: new Fill({color: [0,0,255,0]}),
        stroke: new Stroke({color: [0,0,255,1],width: 1})
      })
    })

    const map = getBaseMap([labelLayer, rect])

    var interaction = new Transform ({
      enableRotatedTransform: false,
      hitTolerance: 2,
      translateFeature: false,
      scale: true,
      rotate: false,
      translate: true,
      stretch: false,
      keepAspectRatio: always
    });

    interaction.on(['translating', 'scaling'], (e) => {
      const feature = e.features.getArray()[0]

      const coor = feature.getGeometry().getCoordinates()[0]
      const layerId = feature.getId()
      const blob = feature.get("blob")

      const ext = [
        Math.min(coor[0][0], coor[1][0], coor[2][0], coor[3][0]),
        Math.min(coor[0][1], coor[1][1], coor[2][1], coor[3][1]),
        Math.max(coor[0][0], coor[1][0], coor[2][0], coor[3][0]),
        Math.max(coor[0][1], coor[1][1], coor[2][1], coor[3][1])
      ]

      mapRef.current.floorplan[layerId].setSource(new ImageStatic({
        url: URL.createObjectURL(blob),
        imageExtent: ext
      }));
    });

    map.addInteraction(interaction);

    const modify = new Modify({source: labelSource});

    map.addInteraction(modify);
    
    mapRef.current = {
      "map": map,
      "labelSource": labelSource,
      "labelLayer": labelLayer,
      "interaction": modify,
      "rect": rect,
      "floorplan": {}
    }

    zoneUpdate(zones)
    addFloorPlan(floorplans)
    setMapExtent(mapExt)
  }, [])

  const zoneUpdate = (data) => {
    setLayerSource(
      mapRef.current.labelLayer, 
      {
        "type": "FeatureCollection",
        "features": data.filter((i) => i["location"] == modifying )[0]["content"].map((i) => ({
          "type": "Feature",
          "id": i["zone_id"],
          "geometry":{
            "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
          },
          "properties":{
            "label": i["zone_name"],
          }
        }))
      }
    )
  }

  const addFloorPlan = (floorplans) => {
    const i = floorplans.filter((j) => j["coor"]["location"] == modifying)[0]

    const newLayer = getImageStatic(i["coor"]["location"], URL.createObjectURL(i["blob"]), [i["coor"]["x1"], i["coor"]["y1"] , i["coor"]["x2"], i["coor"]["y2"]])
    mapRef.current.map.addLayer(newLayer)

    mapRef.current.floorplan[i["coor"]["location"]] = newLayer

    setLayerSource(mapRef.current.rect, {
      "type":"FeatureCollection",
      "features": [{
        "type":"Feature",
        "id": i["coor"]["location"],
        "geometry":{
          "type":"Polygon",
          "coordinates":[[
            transform([i["coor"]["x1"],i["coor"]["y1"]], 'EPSG:3857', 'EPSG:4326'),
            transform([i["coor"]["x1"],i["coor"]["y2"]], 'EPSG:3857', 'EPSG:4326'),
            transform([i["coor"]["x2"],i["coor"]["y2"]], 'EPSG:3857', 'EPSG:4326'),
            transform([i["coor"]["x2"],i["coor"]["y1"]], 'EPSG:3857', 'EPSG:4326'),
            transform([i["coor"]["x1"],i["coor"]["y1"]], 'EPSG:3857', 'EPSG:4326')
          ]]
        },
        "properties":{
          "blob": i["blob"],
          "coor": i["coor"]
        }
      }]
    })
  }

  const setMapExtent = (coordinate) => {
    mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
  }

  const getZones = () => {
    const layer = mapRef.current.labelLayer.getSource().getFeatures()

    return layer.map((i) => ({
      "zone_id": i.getId(),
      "coordinate": transform(i.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326')
    }))
  }

  const getFloorplan = () => {
    const layer = mapRef.current.rect.getSource().getFeatures()

    return layer.map((i) => ({
      "location": i.getId(),
      "coor": i.getGeometry().getCoordinates()[0]
    }))
  }

  useImperativeHandle(ref, () => ({
    getZones: () => {
      return getZones()
    },
    getFloorplan: () => {
      return getFloorplan()
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%" }} id="map" />
  </>)
})

export default ZoneMapEdit

import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form } from 'antd';
import { FaEdit } from "react-icons/fa";

import useCommonAPI from 'Shared/API/Common';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { SelectField, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import useSmartwatchAPI from 'Shared/API/Smartwatch';
import useAuth from 'Shared/Hooks/useAuth';

const SmartwatchConfinedSpaceDevices = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { role } = useAuth()

  const [data, setData] = useState([])
  const [workers, setWorkers] = useState([])
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState({ "device": null, "isOpen": false });

  const tableRef = useRef()

  const filterRef = useRef({
    "search": ""
  })

  const { getWorkers } = useCommonAPI()
  const { getDevices, updateWorkerAssignment } = useSmartwatchAPI()

  const loadData = async () => {
    tableRef.current.setLoading(true)

    const body = await getDevices({ type: "confined_space", search: filterRef.current.search })

    if (body) {
      setData(body)
    }

    tableRef.current.setLoading(false)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData()
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await updateWorkerAssignment({"device_id": formOpen["device"], "worker_id": values["worker_id"]})

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      loadData()
    }

    setLoading(false)
  }

  const toggleForm = async (row) => {
    if (!formOpen["isOpen"]) {
      setLoading(true)

      const w = await getWorkers()
      setWorkers(w)

      form.setFieldsValue({
        "worker_id": row["worker_id"]
      })

      setLoading(false)
    }
    else{
      form.resetFields()
    }
    
    setFormOpen({
      "device": row ? row["device_id"] : null,
      "isOpen": !formOpen["isOpen"]
    })
  }

  const onSearch = async (value) => {    
    filterRef.current.search = value

    await loadData()
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%", marginTop: "5px" }}>
        <Col xs={12} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
            <DataTable
              data={data} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchDevice")}
              columns={{
                "device_id": { "label": t("smartwatch_deviceId"), "sorting": false, "nowrap": true },
                // "model": { "label": t("smartwatch_model"), "sorting": false, "nowrap": true },
                "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
              }}
              renderActions={(row) => { return [
                ["pm", "admin"].includes(role) && {icon: <FaEdit size={20}/>, text: "Edit", onClick: function(row) { toggleForm(row) }},
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={formOpen["isOpen"]} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <SelectField field_name={t("smartwatch_worker")} field_id="worker_id" props={{ "type": "dropdown", "options": workers.map((i) => ({ "value": i["worker_id"], "label": i["worker_name"] })) }}/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchConfinedSpaceDevices
import React, { useRef, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { Map, View, Overlay } from "ol";
import { XYZ, Vector as VectorSource, OSM } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {  Icon, Fill, Text, Style, Circle as CircleStyle, Stroke } from "ol/style";
import { GeoJSON } from "ol/format";
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import { Draw, Modify, Select, Snap } from 'ol/interaction.js';
import { doubleClick } from 'ol/events/condition';
import { transform } from "ol/proj";

import { setLayerSource, getImageStatic, getBaseMap, getZoneLabel } from '../../components/utils'
import '../../components/style.css'

const MapEdit = forwardRef(({ zones, mapExt, mode, selected, onSelect }, ref) => {    
  const mapRef = useRef()

  const addDraw = () => {
    const map = mapRef.current.map
    const drawSource = mapRef.current.drawSource

    const draw = new Draw({
      source: drawSource,
      type: "Polygon",
    })

    draw.on('drawend', function(e) {
      map.removeInteraction(draw);

      addModify()
    });

    map.addInteraction(draw);
  }

  const addModify = () => {
    const map = mapRef.current.map
    const drawSource = mapRef.current.drawSource

    const modify = new Modify({
      source: drawSource
    });

    // modify.on('modifyend', function (e) {
    //   e.features.forEach(function (feature) {
    //     const coor = feature.getGeometry().getCoordinates()
    //     const id = feature.getId()
    //   });
    // });

    map.addInteraction(modify);
  }

  const setMapExtent = (coordinate) => {
    mapRef.current.map.getView().fit([coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]], mapRef.current.map.getSize())
  }

  useLayoutEffect(() => {
    const drawSource = new VectorSource({wrapX: false});

    const drawLayer = new VectorLayer({
      source: drawSource,
      style: {
        'fill-color': 'rgba(255, 255, 255, 0.2)',
        'stroke-color': '#03b1fc',
        'stroke-width': 2,
        'circle-radius': 7,
        'circle-fill-color': '#03b1fc',
      },
    })

    const zoneLayer = new VectorLayer({
      source: new VectorSource({})
    })

    const map = getBaseMap([drawLayer, zoneLayer])

    mapRef.current = {
      "map": map,
      "drawSource": drawSource,
      "drawLayer": drawLayer,
      "zoneLayer": zoneLayer
    }

    let select = null

    switch (zones["zone_type"]) {
      case "danger_zone":
        mapRef.current.zoneLayer.setStyle(new Style({
          stroke: new Stroke({ color: 'rgb(255, 0, 0)', width: 3 }),
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.1)' })
        }));

        select = new Select({
          style: new Style({
            fill: new Fill({
              color: [255, 0, 0, 0.5]
            })
          }),
        });
        
        break
      case "working_zone":
        mapRef.current.zoneLayer.setStyle(new Style({
          stroke: new Stroke({ color: 'rgb(0, 255, 0)', width: 3 }),
          fill: new Fill({ color: 'rgba(0, 255, 0, 0.1)' })
        }));

        select = new Select({
          style: new Style({
            fill: new Fill({
              color: [0, 255, 0, 0.5]
            })
          }),
        });

        break
      default:
        break
    }
    
    if (mode == 0) {
      setLayerSource(zoneLayer, zones["zones"])

      select.on('select', function (e) {
        const features = e.target.getFeatures()

        if (features.getLength() > 0) {
          features.forEach(function (feature) {
            const id = feature.getId()

            onSelect(id)
            return
          });
        }
        else {
          onSelect()
        }
      })

      map.addInteraction(select);
    }
    else if (mode == 1) {
      setLayerSource(zoneLayer, zones["zones"])
      addDraw()
    }
    else if (mode == 2) {
      setLayerSource(zoneLayer, {
        "type": "FeatureCollection",
        "features": zones["zones"]["features"].filter((i) => i["id"] != selected)
      })

      setLayerSource(drawLayer, {
        "type": "FeatureCollection",
        "features": zones["zones"]["features"].filter((i) => i["id"] == selected)
      })
      addModify()
    }

    setMapExtent(mapExt)
  }, [])

  useImperativeHandle(ref, () => ({
    getDrawFeature: () => {
      const layer = mapRef.current.drawLayer.getSource().getFeatures()

      return layer[0].getGeometry().getCoordinates()[0].map((i) => transform(i, 'EPSG:3857', 'EPSG:4326'))
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%" }} id="map" />
  </>)
})

export default MapEdit

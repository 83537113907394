import { Map, View } from "ol";
import { XYZ, Vector as VectorSource, ImageStatic } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer";
import {  Icon, Fill, Text, Style } from "ol/style";
import { GeoJSON } from "ol/format";

import { webUrl } from "Shared/utils";

const mapViewConfiguration = {
  center: [12702887, 2555404],
  extent: [12667814, 2529395, 12737960, 2581413]
}

const getIconStyle = (icon, size, zIndex) => {
  return new Style({
    image: new Icon({
      src: `${webUrl}/icons/${icon}.png`,
      width: size,
      height: size
    }),
    zIndex: zIndex
  })
}

const getLabelStyle = (label, color, zIndex) => {
  return new Style({
    text: new Text({
      font: '14px sans-serif',
      text: label,
      fill: new Fill({
        color: color
      }),
      offsetY: -30
    }),
    zIndex: zIndex
  })
}

const setLayerSource = (layer, data) => {
  layer.getSource().clear()
  layer.getSource().addFeatures(new GeoJSON({ featureProjection:"EPSG:3857" }).readFeatures(data))
}

const getImageStatic = (layerName, url, coor) => {
  const layer = new Image({
    source: new ImageStatic({
      url: url,
      imageExtent: coor
    }),
  })

  if (layerName) {
    layer.set('name', layerName)
  }

  return layer
}

const getBaseMap = (layers) => {
  return (
    new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }), ...layers
      ],
      view: new View({
        center: mapViewConfiguration["center"],
        extent: mapViewConfiguration["extent"],
        zoom: 0,
        showFullExtent: true
      })
    })
  )
}

const getZoneLabel = () => {
  const labelSource = new VectorSource({ })

  const labelLayer = new VectorLayer({
    source: labelSource,
    style: function (feature) {
      return new Style({
        text: new Text({
          font: '14px sans-serif',
          text: `${feature.get("label")}`,
          fill: new Fill({ color: "white" }),
          backgroundFill: new Fill({ color: "grey" }),
          padding: [3, 3, 3, 3]
        })
      })
    }
  })

  labelLayer.set('name', 'labelLayer')
  labelLayer.setZIndex(99)

  return { labelSource, labelLayer }
}

export { getIconStyle, getLabelStyle, setLayerSource, getImageStatic, getBaseMap, getZoneLabel }

import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row, ListGroup } from 'react-bootstrap'
import moment from 'moment';
import { Form, Input } from 'antd';
import { TbArrowsMinimize, TbArrowsMaximize } from "react-icons/tb";
import { IoListOutline } from "react-icons/io5";

import Floorplan from './components/FloorplanDisplay';
import AlertIcons from '../components/AlertIcons';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { SelectField, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import useLoop from 'Shared/Hooks/useLoop';
import { IconButtonComponent } from 'Shared/Components/Icon';
import useAuth from 'Shared/Hooks/useAuth';

const SmartwatchConfinedSpace = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const { role } = useAuth()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [minimize, setMinimize] = useState(false);
  const [floorplanListOpen, setFloorplanListOpen] = useState(false);
  const [floorplanList, setFloorplanList] = useState([]);

  const mapRef = useRef()
  const tableRef = useRef()
  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const data = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/latest`,
      params: {
        "search": filterRef.current.search,
        "type": "confined_space",
      },
      returnType: "json"
    })

    const zones = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/zones`,
      returnType: "json"
    })

    setData(
      data.map((i) => ({
        "online": (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <span style={{ height: "15px", width: "15px", backgroundColor: i["online"] ? "green" : "red", borderRadius: "50%" }}></span>
          </div>
        ),
        "device_id": i["device_id"],
        "worker_id": i["worker_id"],
        "worker_name": i["worker_name"],
        "battery_level": i["battery_level"],
        "body_temp": i["body_temp"] != null ? (role === 'pm' || role === 'admin') ? i["body_temp"] : t(`smartwatch_bodyTemp_${i["body_temp"]}`) : null,
        "heart_rate": i["body_temp"] != null ? (role === 'pm' || role === 'admin') ? i["heart_rate"] : t(`smartwatch_heartRate_${i["heart_rate"]}`) : null,
        "zone_id": i["zone"],
        "zone_name": i["zone_name"],
        "sos": i["sos"],
        "alerts": i["online"] ? <AlertIcons data={i["alerts"]} /> : null,
        "last_update": i["last_update"] != null ? moment(i["last_update"]).format("YYYY-MM-DD HH:mm:SS") : null
      }))
    )

    mapRef.current.zoneUpdate(zones.map((i) => ({
      "zone_id": i["zone_id"],
      "zone_name": i["zone_name"],
      "zone_description": i["zone_description"],
      "marker_lon": i["marker_lon"],
      "marker_lat": i["marker_lat"],
      "workers": data.filter((j) => j["zone"] == i["zone_id"])
    })))
  }

  const fetchFloorPlan = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/floorplan_meta`,
      returnType: "json"
    })

    body.forEach(async (i) => {
      const blob = await send({
        method: "GET",
        url: `${apiUrl}/smartwatch/floorplan`,
        params: {
          "location": i["location"]
        },
        returnType: "blob"
      })

      mapRef.current.addFloorPlan(blob, i)
    });

    setFloorplanList(body)
  }

  const fetchMapExtent = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/map_extent`,
      returnType: "json"
    })

    if (body != null) {
      mapRef.current.setMapExtent(body)
    }
  }
  
  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData(),
        fetchFloorPlan(),
        fetchMapExtent()
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  useLoop(loadData, 10000)

  const onSearch = async (value, _e, info) => {    
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: minimize ? "100%" : "50%", marginBottom: "5px" }}>
        <Col xs={12} style={{ height: "100%", overflow: "hidden", paddingLeft: 0, paddingRight: 0}}>
          <Floorplan ref={mapRef}/>
        </Col>
      </Row>

      {minimize ? (
        <Row style={{ marginTop: "5px", display: minimize ? "block" : "none" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
            <IconButtonComponent text={t("smartwatch_floorplan")} onClick={() => setFloorplanListOpen(true)}>
              <IoListOutline size={20}/>
            </IconButtonComponent>
            
            <IconButtonComponent text={t("general_maximize")} onClick={() => setMinimize(false)}>
              <TbArrowsMaximize size={20}/>
            </IconButtonComponent>
          </div>
        </Row>
      ) : (
        <Row style={{ height: "50%", marginTop: "5px", display: minimize ? "none" : "block" }}>
          <Col xs={12} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
              <DataTable
                data={data} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchWorker")}
                columns={{
                  "online": { "label": t("smartwatch_online"), "sorting": false, "nowrap": true },
                  "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                  "battery_level": { "label": t("smartwatch_betteryLevel"), "sorting": false, "nowrap": true },
                  "body_temp": { "label": t("smartwatch_bodyTemp"), "sorting": false, "nowrap": true },
                  "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                  "zone_name": { "label": t("smartwatch_zone"), "sorting": false, "nowrap": true },
                  "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true },
                  "last_update": { "label": t("smartwatch_lastUpdate"), "sorting": false, "nowrap": true } 
                }}
                appActions={() => { return [
                  {icon: <IoListOutline size={20}/>, text: t("smartwatch_floorplan"), onClick: function() { setFloorplanListOpen(true) }},
                  {icon: <TbArrowsMinimize size={20}/>, text: t("general_minimize"), onClick: function() { setMinimize(true) }},
                ]}}
              />
            </Paper>
          </Col>
        </Row>
      )}
    </Container> 

    <LoadingScreen isOpen={loading} />

    <PopupModal isOpen={floorplanListOpen} width="50%" maxHeight="50%" close toggle={() => setFloorplanListOpen(false)}>
      <ListGroup variant="flush">
        {(floorplanList ?? []).map((i) => 
          <ListGroup.Item className="border-0" action onClick={() => { mapRef.current.focus(i); setFloorplanListOpen(false); }}>
            {`${i["location_name"]}`}
          </ListGroup.Item>
        )}
      </ListGroup>
    </PopupModal>
  </>)
}

export default SmartwatchConfinedSpace

import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { Form } from 'antd';
import { Box, Modal } from '@mui/material';
import { ButtonGroup, Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { MdKeyboardArrowLeft } from "react-icons/md";

import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { DatetimePicker, NumberInput, SelectField, TextArea, TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess, showError } from 'Shared/Components/NotifyToast';
import { apiUrl } from 'Shared/utils';
import useCommonAPI from 'Shared/API/Common';

const PermitToWorkDetail = () => {
  const [form] = Form.useForm();
  const { form_id, template_id } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const { getWorkers } = useCommonAPI()
  const { organization, project } = useParams()

  const [formTemplate, setFormTemplate] = useState([])
  const [fields, setFields] = useState([])
  const [formData, setFormData] = useState({})
  const [formDetail, setFormDeatil] = useState({})
  const [workers, setWorkers] = useState({})

  const [start, setStart] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [formValue, setFormValue] = useState()
  const [loading, setLoading] = useState(true);

  const sigCanvasRef = useRef()

  useEffect(() => {
    const init = async () => {
      const getTemplate = async () => {
        const body = await send({
          method: "GET",
          url: `${apiUrl}/permit_to_work/template`,
          params: {
            "template_id": template_id,
            "form_id": form_id ?? ""
          },
          returnType: "json"
        })

        setFormTemplate(body)

        const fields2 = {}
  
        body.forEach((i) => {
          i["fields"].forEach((j) => {
            if (!i["disabled"]) {
              fields2[j["field_id"]] = j
            }
          })
        })
  
        setFields(fields2)
      }

      const getFormTemplateDetail = async () => {
        const body = await send({
          method: "GET",
          url: `${apiUrl}/permit_to_work/template_detail`,
          params: {
            "template_id": template_id
          },
          returnType: "json"
        })

        setFormDeatil(body)
      }

      const getForm = async () => {
        const body = await send({
          method: "GET",
          url: `${apiUrl}/permit_to_work/form_data`,
          params: {
            "form_id": form_id
          },
          returnType: "json"
        })

        const body2 = await send({
          method: "GET",
          url: `${apiUrl}/permit_to_work/form`,
          params: {
            "form_id": form_id ?? ""
          },
          returnType: "json"
        })
        
        const input_data = {}
  
        for (const [field, value] of Object.entries(body)) {
          if (value["field_type"] === "select") {
            input_data[field] = value["data"].constructor === Array ? value["data"].map((v) => String(v)) : String(value["data"])
          }
          else if (value["field_type"] === "datetime_picker") {
            input_data[field] = dayjs(new Date(value["data"]))
          }
          else {
            input_data[field] = value["data"]
          }
        }

        input_data["owner"] = body2["worker_id"]
        input_data["work_location"] = body2["work_location"]
        input_data["work_description"] = body2["work_description"]
        input_data["permit_start_time"] = dayjs(new Date(body2["permit_start_time"]))
        input_data["permit_end_time"] = dayjs(new Date(body2["permit_end_time"]))

        setFormData(input_data)
        setFormDeatil(body2)
      }
      
      await getTemplate()
  
      if (form_id != null) {
        await getForm()
      }
      else {
        await getFormTemplateDetail()
      }

      const body = await getWorkers()

      setWorkers(body.map((i) => ({
        "value": i.worker_id,
        "label": i.worker_name
      })))
  
      setStart(true)
      setLoading(false)
    }

    init()
  }, [])

  const onSubmit = async () => {
    if (formDetail["signiture"] === true && sigCanvasRef.current.isEmpty() === true) {
      showError("Signiture required")
      return
    }

    const values = formValue

    const data = {}

    for (const field of Object.keys(fields)) {
      let value = values[field]

      if (fields[field]["field_type"] == "datetime_picker") {
        value = moment(new Date(value)).format("YYYY-MM-DDTHH:mm:ss")
      }

      if (value.constructor === Array) {
        value = JSON.stringify(value)
      }

      data[field] = value
    }

    const information = {
      "owner": values["owner"],
      "work_location": values["work_location"],
      "work_description": values["work_description"],
      "permit_start_time": moment(new Date(values["permit_start_time"])).format("YYYY-MM-DDTHH:mm:ss"),
      "permit_end_time": moment(new Date(values["permit_end_time"])).format("YYYY-MM-DDTHH:mm:ss")
    }

    if (!form_id) {
      let form_data = new FormData();
      form_data.append("information", JSON.stringify(information));
      form_data.append("data", JSON.stringify(data));

      if (formDetail["signiture"]) {
        const url = sigCanvasRef.current.toDataURL()
        const blob = await (await fetch(url)).blob()
        form_data.append("signiture", blob);
      }

      const res = await send({
        method: "POST",
        url: `${apiUrl}/permit_to_work/form/${template_id}`,
        contentType: "formdata",
        body: form_data
      })

      if (res != null) {
        showSuccess("Form created")
        navigate(`/portal/${organization}/${project}/permit_to_work/list`)
      }
    }
    else {
      let form_data = new FormData();
      form_data.append("data", JSON.stringify(data));

      if (formDetail["signiture"]) {
        const url = sigCanvasRef.current.toDataURL()
        const blob = await (await fetch(url)).blob()
        form_data.append("signiture", blob);
      }

      const res = await send({
        method: "PUT",
        url: `${apiUrl}/permit_to_work/form/${form_id}`,
        contentType: "formdata",
        body: form_data
      })

      if (res != null) {
        showSuccess("Form updated")
        navigate(`/portal/${organization}/${project}/permit_to_work/list`)
      }
    }
  };

  const onFinishFailed = () => {
    showError("Error")
  };

  const onFinish = (values) => {
    setFormValue(values)

    setModalOpen(true)
  }

  const download = async () => {
    const blob = await send({
      method: "GET",
      url: `${apiUrl}/permit_to_work/export`,
      params: {
        "form_id": form_id ?? ""
      },
      returnType: "blob"
    })

    saveAs(blob, `[${formDetail["template_name"]}] ${formData["owner"]}.pdf`)
  }

  return (<>
    <Button variant="link" style={{ textDecoration: "none" }} onClick={() => navigate(`/portal/${organization}/${project}/permit_to_work/list`)}><MdKeyboardArrowLeft /> Back</Button>

    {start && <>
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={formData} style={{ width: "100%", padding: "10px" }}>
        <div>Form Type: {formDetail["template_name"]}</div>
        <SelectField field_name="Owner" field_id="owner" props={{"type": "dropdown", "options": workers}} required={form_id == null} disabled={form_id != null}/>
        <TextInput field_name="Work Location" field_id="work_location" required={form_id == null} disabled={form_id != null}/>
        <TextInput field_name="Work Description" field_id="work_description" required={form_id == null} disabled={form_id != null}/>
        <DatetimePicker field_name="Permit Start Time" field_id="permit_start_time" required={form_id == null} disabled={form_id != null} props={{"type": "datetime", "min": new Date()}}/>
        <DatetimePicker field_name="Permit End Time" field_id="permit_end_time" required={form_id == null} disabled={form_id != null} props={{"type": "datetime", "min": new Date()}}/>

        {formTemplate.map((i) => <>
          <h4>{i["session_header"]}</h4>
          <p>{i["session_description"]}</p>

          {i["fields"].map((j) => {
            switch(j.field_type) {
              case "text_input":
                return (<TextInput disabled={i["disabled"]} {...j}/>)
              case "number_input":
                  return (<NumberInput disabled={i["disabled"]} {...j}/>)
              case "text_area":
                return (<TextArea disabled={i["disabled"]} {...j}/>)
              case "select":
                return (<SelectField disabled={i["disabled"]} {...j}/>)
              case "datetime_picker":
                return (<DatetimePicker disabled={i["disabled"]} {...j}/>)
            }
          })}
        </>)}

        {!form_id && (
          <ButtonGroup style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </ButtonGroup>
        )}

        {(form_id && formDetail["status"] >= 0) && (
          <ButtonGroup style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </ButtonGroup>
        )}

        {(form_id && formDetail["status"] < 0) && (
          <ButtonGroup style={{ width: "100%" }}>
            <Button onClick={download}>
              Export
            </Button>
          </ButtonGroup>
        )}
      </Form>

      <Modal open={modalOpen}>
        <Box
          sx={{
            top: '50%',
            left: '50%',
            width: '500px',
            maxHeight: "60%",
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: "8px",
            boxShadow: "5px 5px 7px rgba(47, 29, 36, 0.29)",
            p: 2
          }} 
          style={{ flexGrow: "1", overflowY: "auto" }}
        >
          {modalOpen && <>
            <p style={{ fontSize: "16px", fontWeight: "400" }}>Confirm Submission?</p>

            {formDetail["signiture"] && 
              <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <div style={{ width: "400px" }}>
                  <div>Sign to confirm Submission</div>
                  <div style={{ border: '2px solid black' } }>
                    <SignatureCanvas
                      ref={sigCanvasRef}
                      penColor='blue' 
                      canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                    />
                  </div>
                </div>
              </div>
            }
            
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
              <Button variant="info" size="sm" style={{ width: "120px", marginLeft: "10px" }} onClick={onSubmit}>Confirm</Button>
              <Button variant="outline-secondary" size="sm" style={{ width: "120px", marginLeft: "10px" }} onClick={() => setModalOpen(false)}>Cancel</Button>
            </div>
          </>}
        </Box>
      </Modal>
    </>}


    <LoadingScreen isOpen={loading} />
  </>)
}

export default PermitToWorkDetail
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material'
import { MdOutlineSos, MdCrisisAlert } from 'react-icons/md';
import { FaTemperatureHigh, FaHeartbeat } from "react-icons/fa";
import { LuPersonStanding } from "react-icons/lu";
import { FaPersonFalling } from "react-icons/fa6";
import { BsFillGeoFill } from "react-icons/bs";

import { IconComponent } from 'Shared/Components/Icon';

import React from 'react'

const AlertIcons = ({ data }) => {
  const { t } = useTranslation()

  const alertIcons = {
    "sos": {
      "text": "smartwatch_sos",
      "icon": <MdOutlineSos />
    },
    "still" : {
      "text": "smartwatch_still",
      "icon": <LuPersonStanding />
    },
    "fall": {
      "text": "smartwatch_fall",
      "icon": <FaPersonFalling />
    },
    "body_temp": {
      "text": "smartwatch_AlertBodyTemp",
      "icon": <FaTemperatureHigh />
    },
    "heart_rate": {
      "text": "smartwatch_AlertHeartRate",
      "icon": <FaHeartbeat />
    },
    "geofencing": {
      "text": "smartwatch_Geofencing",
      "icon": <BsFillGeoFill />
    }
  }

  const activeWarning = Object.keys(alertIcons).filter((i) => data[i] == true)
    
  if (activeWarning.length === 0) {
    return <div>-</div>
  }

  return (
    <Stack justifyContent="center" direction="row" spacing={2}>
      {activeWarning.map((i) =>
        <IconComponent title={t(alertIcons[i]["text"])}>
          {alertIcons[i]["icon"]}
         </IconComponent>
      )}
    </Stack>
  )
}

export default AlertIcons

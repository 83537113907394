import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Paper, Stack, Chip, Typography } from '@mui/material'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { AiOutlineSolution } from "react-icons/ai";
import { FaFileImage, FaFileVideo, FaFileDownload } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { FaRegSquareCheck } from "react-icons/fa6";
import { GoIssueReopened } from "react-icons/go";
import { RxActivityLog } from "react-icons/rx";
import { PiExportBold } from "react-icons/pi";
import { Form, TreeSelect, DatePicker, Checkbox } from 'antd';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import { IconButtonComponent } from 'Shared/Components/Icon';
import { showSuccess } from 'Shared/Components/NotifyToast';
import PlaybackModal from './components/PlaybackModal';
import PopupModal from 'Shared/Components/PopupModal';
import useLoop from 'Shared/Hooks/useLoop';
import useAuth from 'Shared/Hooks/useAuth';

const activityLogComponents = {
  "acknowledge": {
    "icon": <AiOutlineSolution />,
    "label": "alert_acknowledge"
  },
  "resolve": {
    "icon": <FaRegSquareCheck />,
    "label": "alert_resolve"
  },
  "reopen": {
    "icon": <GoIssueReopened />,
    "label": "alert_reopen"
  }
}

const alertTypes = {
  "smartwatch": "module_smartwatch",
  "proximity": "module_proximity",
  "ai_detection": "module_aiDetection",
}

const alertEvents = {
  "smartwatch": {
    "alertSOS": "smartwatch_sos",
    "alertFall": "smartwatch_fall",
    "alertStill": "smartwatch_still",
    "alert_BodyTemp": "smartwatch_AlertBodyTemp",
    "alert_HeartRate": "smartwatch_AlertHeartRate",
    "alert_BloodPressure": "smartwatch_AlertBloodPressure",
    "alert_BloodOxygen": "smartwatch_AlertBloodOxygen",
    "alert_Geofencing": "smartwatch_AlertGeofencing"
  },
  "proximity": {
    "danger_zone": "proximity_dangerZone"
  },
  "ai_detection": {
    "ppe": "aiDetection_ppe",
    "fall": "aiDetection_fall"
  }
}

const Alert = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();
  const { role } = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const [alertData, setAlertData] = useState({})
  const [loading, setLoading] = useState(true);
  const [alertSnapshot, setAlertSnapshot] = useState(null);
  const [alertVideo, setAlertVideo] = useState(null);
  const [filterFormOpen, setFilterFormOpen] = useState(false);
  const [showHistory, setShowHistory] = useState();

  const tableRef = useRef()

  const filterRef = useRef({
    "search": [],
    "date": [],
    "status": []
  })

  const getAlertData = async (showPopup) => {    
    const fetchData = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/alert/history`,
        params: {
          "offset": (tableRef.current.getState()["page"]-1)*tableRef.current.getState()["itemsPerPage"],
          "limit": tableRef.current.getState()["itemsPerPage"],
          "search": filterRef.current.search.join("|"),
          "date": filterRef.current.date.map((i) => moment(new Date(i)).format("YYYY-MM-DD")).join("|"),
          "status": filterRef.current.status .join("|"),
        },
        returnType: "json"
      })
  
      return body
    }

    const getAlertDetail = (type) => {
      const getSnapshot = async (id) => {
        setAlertSnapshot(id)
      }
  
      const getVideo = async (id) => {
        setAlertVideo(id)
      }
  
      const download = async (id) => {
        setLoading(true)
  
        const blob = await send({
          method: "GET",
          url: `${apiUrl}/alert/download`,
          params: {
            "record_id": id
          },
          returnType: "blob"
        })
  
        if (blob != null) {
          saveAs(blob, `${id}.zip`)
        }
  
        setLoading(false)
      }
  
      switch (type) {
        case "ai_detection":
        case "proximity":
          return [
            {icon: <FaFileImage size={15}/>, text: t("alert_snapshot"), onClick: function(id) { getSnapshot(id) }},
            {icon: <FaFileVideo size={15}/>, text: t("alert_video"), onClick: function(id) { getVideo(id) }},
            {icon: <FaFileDownload size={15}/>, text: t("alert_download"), onClick: function(id) { download(id)}}
          ]
  
        default:
          return []
      }
    }
  
    const formatAlertStatus = (item) => {
      switch (item["status"]) {
        case 0:
          return (
            <div>
              <Chip label={t("alert_pending")} color="warning" variant="outlined" />
            </div>
          )
        case 1:
          return (
            <div>
              <Chip label={t("alert_acknowledged")} color="primary" variant="outlined" />
            </div>
          )
        case 2:
          return (
            <div>
              <Chip label={t("alert_resolved")} color="success" variant="outlined" />
            </div>
          )
  
        default:
          return <div></div>
      }
    }
  
    const formatAlertDetail = (item) => {
      return (
        <Stack justifyContent="center" direction="row" spacing={0}>
          {getAlertDetail(item["type"]).map((action) => 
            <IconButtonComponent title={action.text} onClick={() => action.onClick(item.record_id) }>
              {action.icon}
            </IconButtonComponent>
          )}
        </Stack>
      )
    }

    const body = await fetchData()

    const data = []
    const selectInitial = {}

    const selectState = tableRef.current.getState()["selected"]

    body["data"].forEach((i) => {
      const id = new String(i.record_id).toString()

      data.push({
        "id": id,
        "datetime": i["datetime"],
        "type": t(alertTypes[i["type"]]),
        "event": t(alertEvents[i["type"]][i["event"]]),
        "target": i["target"],
        "status": formatAlertStatus(i),
        "detail": formatAlertDetail(i)
      })

      

      if (showPopup) {
        selectInitial[id] = (selectState[id] == true)

        if (selectState[id] == null) {
          const item = data.filter((i) => i["id"] == id)[0]
  
          enqueueSnackbar(`${item["type"]} (${item["event"]}) | ${item["target"]}`, { variant: 'warningToast' });
        }
      }
      else {
        selectInitial[id] = false
      }
    });

    setAlertData({
      "data": data,
      "count": Math.ceil(body["count"]/tableRef.current.getState()["itemsPerPage"])
    })

    tableRef.current.setSelect(selectInitial)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        getAlertData(false)
      ]);

      setLoading(false)
    }

    init()
  }, [])

  const loadData = async () => {
    tableRef.current.setLoading(true)

    await getAlertData(false)

    tableRef.current.setLoading(false)
  }

  const update = async (action) => {
    setLoading(true)

    const records = Object.entries(tableRef.current.getState()["selected"]).filter(([k, v]) => v === true).map(([k, v]) => k)

    const res = await send({
      method: "POST",
      url: `${apiUrl}/alert/update/${action}`,
      contentType: "json",
      body: {
        "records": JSON.stringify(records)
      }
    })

    if (res != null) {
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await loadData()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const showActivities = async (record_id) => {
    setLoading(true)

    const body = await send({
      method: "GET",
      url: `${apiUrl}/alert/activity_log`,
      returnType: "json",
      params: {
        "record_id": record_id
      }
    })

    setShowHistory(body)

    setLoading(false)
  }
  
  const onFinish = (value) => {
    filterRef.current.search = value["items"]
    filterRef.current.date = value["date"]
    filterRef.current.status = value["status"]

    setFilterFormOpen(false)
    tableRef.current.setPage(1)

    loadData()
  }

  const report = async () => {
    setLoading(true)

    const blob = await send({
      method: "GET",
      url: `${apiUrl}/alert/report`,
      params: {
        "search": filterRef.current.search.join("|"),
        "date": filterRef.current.date.map((i) => moment(new Date(i)).format("YYYY-MM-DD")).join("|"),
        "status": filterRef.current.status .join("|"),
      },
      returnType: "blob"
    })

    if (blob != null) {
      saveAs(blob, `export.xlsx`)
    }

    setLoading(false)
  }

  useLoop(async function() {
    getAlertData(true)
  }, 10000)

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={alertData["data"]} count={alertData["count"]} loadData={() => loadData()} ref={tableRef}
              showCheck={["pm", "so", "admin"].includes(role)}
              columns={{
                "datetime": { "label": t("alert_datetime"), "sorting": false, "nowrap": true },
                "type": { "label": t("alert_type"), "sorting": false, "nowrap": true },
                "event": { "label": t("alert_event"), "sorting": false, "nowrap": true },
                "target": { "label": t("alert_target"), "sorting": false, "nowrap": true },
                "detail": { "label": t("alert_detail"), "sorting": false, "nowrap": true },
                "status": { "label": "", "sorting": false, "nowrap": true },
              }}
              appActions={() => { return [
                {icon: <IoFilter size={20}/>, text: t("general_filter"), onClick: function() { form.setFieldsValue({ "items": filterRef.current.search, "date": filterRef.current.date }); setFilterFormOpen(true); }},
                {icon: <PiExportBold size={20}/>, text: t("general_export"), onClick: function() { report() }}
              ]}}
              renderActions={(row) => { return [
                ["pm", "so", "admin"].includes(role) && {icon: <RxActivityLog size={15}/>, text: t("general_activityLog"), onClick: function(row) { showActivities(row["id"]) }},
              ]}}
              selectActions={(row) => { return [
                ["pm", "so", "admin"].includes(role) && {icon: <AiOutlineSolution size={20}/>, text: t("alert_acknowledge"), onClick: function() { update("acknowledge"); }},
                ["pm", "so", "admin"].includes(role) && {icon: <FaRegSquareCheck size={20}/>, text: t("alert_resolve"), onClick: function() { update("resolve"); }},
                ["pm", "so", "admin"].includes(role) && {icon: <GoIssueReopened size={20}/>, text: t("alert_reopen"), onClick: function() { update("reopen"); }}
              ]}}
            />
          </Paper>
        </Col>
      </Row>
    </Container>

    <PlaybackModal type="snapshot" record_id={alertSnapshot} toggle={() => setAlertSnapshot(null)}/>
    <PlaybackModal type="video" record_id={alertVideo} toggle={() => setAlertVideo(null)}/>

    <LoadingScreen isOpen={loading} />

    <PopupModal isOpen={filterFormOpen} close toggle={() => setFilterFormOpen(false)} height="80%" width="80%">
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>
        <Form.Item label={t("alert_searchDate")} name="date">
          <DatePicker.RangePicker
            style={{ width: "100%" }} maxDate={dayjs(moment().format("YYYY-MM-DD"))}
            popupStyle={{ zIndex: 1500 }}
          />
        </Form.Item>

        <Form.Item label={t("alert_searchItems")} name="items">
          <TreeSelect
            treeData={Object.entries(alertEvents).map(([k1, v1]) =>({
              "title": t(alertTypes[k1]), "value": k1, "key": k1,
              "children": Object.entries(v1).map(([k2, v2]) =>(
                { "title": t(v2), "value": k2, "key": k2, }   
              ))
            }))}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            style={{
              width: '100%'
            }}
            dropdownStyle={{ zIndex: 1500 }}
          />
        </Form.Item>

        <Form.Item label={t("alert_status")} name="status">
          <Checkbox.Group style={{ width: "100%" }} options={[
            { label: t("alert_pending"), value: '0' },
            { label: t("alert_acknowledged"), value: '1' },
            { label: t("alert_resolved"), value: '2' },
          ]} />
        </Form.Item>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <PopupModal isOpen={showHistory != null} close toggle={() => setShowHistory()} width="50%" maxHeight="60%">
      {showHistory && <>
        {(showHistory.length > 0) ? (
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2 },
            }}
          >
            {Array.from(showHistory.entries()).map(([idx, i]) =>
              <TimelineItem>
                <TimelineOppositeContent color="textSecondary"></TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>
                    {activityLogComponents[i["activity"]]["icon"]}
                  </TimelineDot>

                  {(idx < showHistory.length - 1) &&
                    <TimelineConnector />
                  }
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <Typography variant="body1">{t(activityLogComponents[i["activity"]]["label"])}</Typography>
                  <Typography variant="caption">{i["performed_by"]}, {i["updated_at"]}</Typography>
                </TimelineContent>
              </TimelineItem>
            )}
          </Timeline>
        ) : (
          <div style={{ padding: "20px", width: "100%", display: "flex", justifyContent: "center" }}>
            <div><b>No Activity Found</b></div>
          </div>
        )}
      </>}
    </PopupModal>
  </>)
}

export default Alert

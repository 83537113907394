import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Paper, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import { TbArrowsMinimize, TbArrowsMaximize } from "react-icons/tb";
import { MdDraw } from "react-icons/md";

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import { IconButtonComponent } from 'Shared/Components/Icon';
import useAuth from 'Shared/Hooks/useAuth';

import ZoneMap from './components/ZoneMap';
import ZoneMapEdit from './components/ZoneMapEdit';
import useWindowSize from 'Shared/Hooks/useWindowSize';

const SmartwatchConfinedSpaceZones = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const [locationForm] = Form.useForm();
  const [zoneForm] = Form.useForm();
  const mapRef = useRef()
  const windowSize = useWindowSize()

  const { role } = useAuth()

  const [locationFormOpen, setLocationFormOpen] = useState({ "location": null, "isOpen": false });
  const [zoneFormOpen, setZoneFormOpen] = useState({ "zone": null, "isOpen": false });
  const [zones, setZones] = useState()
  const [floorplans, setFloorplans] = useState()
  const [mapExt, setMapExt] = useState()
  const [loading, setLoading] = useState(true);
  const [modifying, setModifying] = useState();
  const [minimize, setMinimize] = useState(false);

  const tableRef = useRef()

  const fetchFloorPlan = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/floorplan_meta`,
      returnType: "json"
    })

    const floorplans = []

    const data = []

    for await (const i of body) {
      const blob = await send({
        method: "GET",
        url: `${apiUrl}/smartwatch/floorplan`,
        params: {
          "location": i["location"]
        },
        returnType: "blob"
      })

      const zone = await send({
        method: "GET",
        url: `${apiUrl}/smartwatch/zones`,
        params: {
          "location": i["location"]
        },
        returnType: "json"
      })

      floorplans.push({"blob": blob, "coor": i})

      data.push({
        "id": i["location"],
        "location": i["location"],
        "locationName": i["location_name"],
        "address": i["address"],
        "content": zone
      })
    }

    setFloorplans(floorplans)
    setZones(data)
  }

  const onFinish = async (values) => {
    setLoading(true)

    let res

    if (zoneFormOpen["isOpen"]) {
      res = await send({
        method: "PUT",
        url: `${apiUrl}/smartwatch/zone/${zoneFormOpen["zone"]}`,
        contentType: "json",
        body: {
          "zone_name": values["zone_name"],
          "zone_description": values["zone_description"]
        }
      })
    }
    else if (locationFormOpen["isOpen"]) {
      res = await send({
        method: "PUT",
        url: `${apiUrl}/smartwatch/location/${locationFormOpen["location"]}`,
        contentType: "json",
        body: {
          "location_name": values["locationName"],
          "address": values["address"]
        }
      })
    }

    if (res != null) {
      toggleZoneForm()
      toggleLocationForm()

      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await fetchFloorPlan()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleLocationForm = (row) => {
    if (row) {
      locationForm.setFieldsValue({
        "locationName": row["locationName"],
        "address": row["address"]
      })
    }
    else {
      locationForm.resetFields()
    }

    setLocationFormOpen({
      "location": row ? row["location"] : null,
      "isOpen": row != null
    })
  }

  const toggleZoneForm = (row2) => {
    if (row2) {
      zoneForm.setFieldsValue({
        "zone_name": row2["zone_name"],
        "zone_description": row2["zone_description"]
      })
    }
    else {
      zoneForm.resetFields()
    }

    setZoneFormOpen({
      "zone": row2 ? row2["zone_id"] : null,
      "isOpen": row2 != null
    })
  }

  const fetchMapExtent = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/map_extent`,
      returnType: "json"
    })

    setMapExt(body)
  }

  const toggleDraw = (location) => {
    setModifying(location ? location["location"] : null)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        fetchFloorPlan(),
        fetchMapExtent()
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  const submit = async () => {
    const zones = mapRef.current.getZones()
    const floorplans = mapRef.current.getFloorplan()

    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/location_coor/${modifying}`,
      contentType: "json",
      body: {
        "zones": JSON.stringify(zones),
        "floorplans": JSON.stringify(floorplans.map((i) => ({
          "location": i["location"],
          "coor": [
            Math.min(i["coor"][0][0], i["coor"][1][0], i["coor"][2][0], i["coor"][3][0]),
            Math.min(i["coor"][0][1], i["coor"][1][1], i["coor"][2][1], i["coor"][3][1]),
            Math.max(i["coor"][0][0], i["coor"][1][0], i["coor"][2][0], i["coor"][3][0]),
            Math.max(i["coor"][0][1], i["coor"][1][1], i["coor"][2][1], i["coor"][3][1])
          ]
        })))
      }
    })

    if (res != null) {
      showSuccess(t("general_success"))

      setZones()
      setFloorplans()
      setMapExt()

      await Promise.all([
        fetchFloorPlan(),
        fetchMapExtent()
      ]);
    }

    setModifying()
    setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: minimize ? "100%" : "50%", marginBottom: "5px" }}>
        <Col xs={12} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
          {((modifying == null && zones && floorplans && mapExt) &&
            <ZoneMap zones={zones} floorplans={floorplans} mapExt={mapExt}/>
          )}           
        </Col>
      </Row>

      {minimize ? (
        <Row style={{ marginTop: "5px", display: minimize ? "block" : "none" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
            <IconButtonComponent  text={t("general_maximize")} onClick={() => setMinimize(false)}>
              <TbArrowsMaximize size={15}/>
            </IconButtonComponent>
          </div>
        </Row>
      ) : (
        <Row style={{ height: "50%" }}>
          <Col xs={12} style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={zones} count={null} ref={tableRef}
                columns={{
                  "locationName": { "label": t("smartwatch_location"), "sorting": false, "nowrap": true },
                  "address": { "label": t("smartwatch_address"), "sorting": false, "nowrap": true },
                }}
                subColumns={{
                  "zone_name": { "label": t("smartwatch_zoneName"), "sorting": false, "nowrap": true },
                  "zone_description": { "label": t("smartwatch_zoneDescription"), "sorting": false, "nowrap": true },
                }}
                renderActions={(row) => { return [
                  ["pm", "admin"].includes(role) && {icon: <FaEdit size={20}/>, text: t("general_edit"), onClick: function(row) { toggleLocationForm(row) }},
                  ["pm", "admin"].includes(role) && {icon: <MdDraw size={20}/>, text: t("general_edit"), onClick: function(row) { toggleDraw(row) }},
                ]}}
                renderSubActions={(row) => { return [
                  ["pm", "admin"].includes(role) && {icon: <FaEdit size={20}/>, text: t("general_edit"), onClick: function(row1, row2) { toggleZoneForm(row2) }},
                ]}}
                appActions={() => { return [
                  {icon: <TbArrowsMinimize size={20}/>, text: t("general_minimize"), onClick: function() { setMinimize(true) }}
                ]}}
              />
            </Paper>
          </Col>
        </Row>
      )}
    </Container> 

    <PopupModal isOpen={zoneFormOpen["isOpen"]} close toggle={() => toggleZoneForm()} width="80%">
      <Form form={zoneForm} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <TextInput field_name={t("smartwatch_zoneName")} field_id="zone_name"/>
        <TextInput field_name={t("smartwatch_zoneDescription")} field_id="zone_description"/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <PopupModal isOpen={locationFormOpen["isOpen"]} close toggle={() => toggleLocationForm()} width="80%">
      <Form form={locationForm} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <TextInput field_name={t("smartwatch_location")} field_id="locationName"/>
        <TextInput field_name={t("smartwatch_address")} field_id="address"/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <PopupModal isOpen={modifying != null} close toggle={() => toggleDraw()} width={windowSize["width"]*0.8} height={windowSize["height"]*0.8}>
      <div style={{ display: "flex", justifyContent: "end", padding: "5px 10px 5px 5px" }}>
        <Stack direction="row">
          <IconButtonComponent title={t("general_submit")} onClick={() => submit()}>
            <FaCheck size={20}/>
          </IconButtonComponent>
        </Stack>
      </div>

      <ZoneMapEdit ref={mapRef} modifying={modifying} zones={zones} floorplans={floorplans} mapExt={mapExt}/>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchConfinedSpaceZones
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { Container, Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { Paper, Stack } from '@mui/material';
import moment from 'moment'
import { saveAs } from 'file-saver'

import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import TimeSeriesPlot2 from 'Shared/Components/TimeSeriesPlot2';
import DataTable from 'Shared/Components/DataTable';
import { FloatingDatePicker, FloatingDropdown, FloatingTimeRangePicker } from 'Shared/Components/FloatingLabel/FloatingLabel';

const SmartwatchConfinedSpaceHistory = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [workers, setWorkers] = useState([])
  const [workerSelected, setWorkerSelected] = useState()

  const [data, setData] = useState({
    "data": [],
    "zone": []
  })
  const [threshold, setThreshold] = useState({})

  const [date, setDate] = useState()
  const [time, setTime] = useState()

  const [plotRange, setPlotRange] = useState({
    "start": 0,
    "end": 24
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const init = async () => {
      await onDateChange(new Date())
      setLoading(false)
    }

    init()
  }, [])

  const loadData = async () => {
    const body1 = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/threshold`,
      returnType: "json"
    })

    const th = {}

    Object.entries(body1).forEach(([k, v]) => {
      v.forEach((i) => {
        if (i["alert"] === true) {
          th[`${k}-${i["range"][0]}-${i["range"][1]}`] = i["range"]
        }
      })
    });

    setThreshold(th)

    const body2 = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/history`,
      params: {
        "worker": workerSelected,
        "start_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[0]).format("HH:mm:ss")}`).unix(),
        "end_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[1]).format("HH:mm:ss")}`).unix(),
        "type": "confined_space"
      },
      returnType: "json"
    })

    const d = []

    const z = []

    let z_start = null
    let z_end = null
    let z_zone = null

    if (body2 != null) {
      body2.forEach((i) => {
        if (z_zone == null) {
          z_start = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          z_zone = i["zone_name"]
        }
        else {
          if (z_zone == i["zone_name"]) {
            z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          }
          else {
            z.push({
              "zone": z_zone,
              "start": z_start,
              "end": z_end
            })
  
            z_start = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
            z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
            z_zone = i["zone_name"]
          }
        }
  
        d.push({...th, ...{
          "time": new Date(moment(i["updated_at"])),
          "body_temp": i["body_temp"],
          "heart_rate": i["heart_rate"]
        }})
      });
  
      z.push({
        "zone": z_zone,
        "start": z_start,
        "end": z_end
      })

      setData({
        "data": [
          ...[{...th, ...{"time": moment.unix(0)}}],
          ...d,
          ...[{...th, ...{"time": moment.unix(2147483648)}}],
        ],
        "zone": z
      })
    }
    else {
      setData({
        "data": [],
        "zone": []
      })
    }

    setLoading(false)
  }

  const onDateChange = async (date) => {
    setDate(date)

    setLoading(true)

    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/worker`,
      params: {
        "type": "confined_space",
        "date": moment(date).format("YYYY-MM-DD")
      },
      returnType: "json"
    })

    body && setWorkers(body.map((i) => ({
      value: i["worker_id"].toString(),
      label: i["worker_name"],
    })))

    setLoading(false)
  }

  const update = async () => {
    if (workerSelected && date && time) {
      setLoading(true)

      await loadData()

      const start = parseInt(moment(time[0]).startOf('hour').format('HH'))

      let end = moment(time[1])
      if (end.format("mm") !== "00") { end = end.add(1, "hours").startOf('hours') }
      end = end.format('HH')

      setPlotRange({
        "start": start,
        "end": end != "00" ? parseInt(end) : 24
      })

      setLoading(false)
    }
  } 

  const onWorkerSelected = (v) => {
    const start = moment(date);
    start.set({hour:0,minute:0,second:0})

    const end = moment(date);
    end.set({hour:23,minute:59,second:59})

    setWorkerSelected(v)
    setTime([new Date(start), new Date(end)])
  }

  const report = async () => {
    setLoading(true)

    const blob = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/report`,
      params: {
        "worker": workerSelected,
        "start_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[0]).format("HH:mm:ss")}`).unix(),
        "end_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(time[1]).format("HH:mm:ss")}`).unix(),
        "type": "confined_space"
      },
      returnType: "blob"
    })

    if (blob != null) {
      saveAs(blob, `export.xlsx`)
    }

    setLoading(false)
  }

  return (<>
    <div className="mainContainer"  style={{ borderRadius: "15px", backgroundColor: "white", padding: "20px" }}>
      <Container fluid style={{ height: "100%", overflow: "auto" }} className="scollbar">
        <Row className="mt-1 mb-1">
          <Col xs={12} lg={4}>
            <FloatingDatePicker label="Date" name="date" value={date} onChange={(e) => onDateChange(new Date(e))}/>
          </Col>

          <Col xs={12} lg={4}>
            <FloatingDropdown label="Worker Name" name="name" value={workerSelected} options={workers} onChange={(e) => onWorkerSelected(e)} />
          </Col>

          <Col xs={12} lg={4}>
            <FloatingTimeRangePicker label="Time Range" name="time" value={time}  onChange={(d) => setTime([new Date(d[0]), new Date(d[1])]) }/>
          </Col>

          <Col xs={12} className="mt-2">
            <ButtonGroup style={{ width: "100%" }}>
              <Button variant="success" onClick={() => update()}>{t("general_search")}</Button>
              <Button variant="info" onClick={() => report()}>{t("general_export")}</Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col xs={12} style={{ height: "100%", padding: "10px" }}>
            <Stack spacing={3}>
              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("smartwatch_bodyTemp")}
                </div>

                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data["data"] ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "body_temp")}
                    items={[
                      { "axis": "left", "dataKey":"body_temp", "name": t("smartwatch_bodyTemp"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 45], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("smartwatch_heartRate")}
                </div>
                
                <div style={{ flexGrow: 1, margin: "5px" }} >
                  <TimeSeriesPlot2
                    data={data["data"] ?? []}
                    threshold_key={Object.keys(threshold).filter((i) => i.split("-")[0] === "heart_rate")}
                    items={[
                      { "axis": "left", "dataKey":"heart_rate", "name": t("smartwatch_heartRate"), "stroke": "#8884d8" },
                    ]}
                    day={moment(date).format("YYYY-MM-DD")}
                    hour={[plotRange["start"], plotRange["end"]]}
                    yLeft={{ "range": [0, 140], "label": "" }}
                  />
                </div>
              </div>

              <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {t("smartwatch_zone")}
                </div>

                <Paper elevation={0} sx={{ flex: 1, display: "flex", flexFlow: "column", overflow: "auto", borderRadius: "8px" }}>
                  <DataTable
                    data={data["zone"]} count={null}
                    columns={{
                      "zone": { "label": t("smartwatch_zone"), "sorting": false, "nowrap": true },
                      "start": { "label": t("smartwatch_startTime"), "sorting": false, "nowrap": true },
                      "end": { "label": t("smartwatch_endTime"), "sorting": false, "nowrap": true },
                    }}
                  />
                </Paper>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container> 
    </div>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>)
}

export default SmartwatchConfinedSpaceHistory
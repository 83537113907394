import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { MdCenterFocusStrong } from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from "react-icons/tb";
import moment from 'moment';

import MapDisplay from './components/MapDisplay';
import AlertIcons from '../components/AlertIcons';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import useLoop from 'Shared/Hooks/useLoop';
import { apiUrl } from 'Shared/utils';
import { IconButtonComponent } from 'Shared/Components/Icon';
import useAuth from 'Shared/Hooks/useAuth';

const SmartwatchOutdoor = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { role } = useAuth()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [minimize, setMinimize] = useState(false);

  const mapRef = useRef(null);
  const tableRef = useRef()

  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/latest`,
      params: {
        "search": filterRef.current.search,
        "type": "outdoor",
      },
      returnType: "json"
    })

    if (body) {
      setData(
        body.features.map((i) => ({
          "online": (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <span style={{ height: "15px", width: "15px", backgroundColor: i["properties"]["online"] ? "green" : "red", borderRadius: "50%" }}></span>
            </div>
          ),
          "device_id": i["properties"]["device_id"],
          "worker_id": i["properties"]["worker_id"],
          "worker_name": i["properties"]["worker_name"],
          "battery_level": i["properties"]["battery_level"],
          "body_temp": i["properties"]["body_temp"] != null ? (role === 'pm' || role === 'admin') ? i["properties"]["body_temp"] : t(`smartwatch_bodyTemp_${i["properties"]["body_temp"]}`) : null,
          "heart_rate": i["properties"]["heart_rate"] != null ? (role === 'pm' || role === 'admin') ? i["properties"]["heart_rate"] : t(`smartwatch_heartRate_${i["properties"]["heart_rate"]}`) : null,
          "sos": i["properties"]["alerts"]["sos"],
          "alerts": i["properties"]["online"] ? <AlertIcons data={i["properties"]["alerts"]} /> : null,
          "last_update": i["properties"]["last_update"] != null ? moment(i["properties"]["last_update"]).format("YYYY-MM-DD HH:mm:SS") : null
        }))
      )

      mapRef.current.updateData(body)
    }
  }

  const focus = (id, zoom) => {
    const feature = mapRef.current.getFeature(id)

    mapRef.current.zoomToFeature(id, feature, zoom)
  }

  const fetchMapExtent = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/map_extent`,
      returnType: "json"
    })

    if (body != null) {
      mapRef.current.setMapExtent(body)
    }
  }

  const fetchGeofencingZones = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/geofencing_zones`,
      returnType: "json"
    })

    if (body["zone_type"] != null) {
      mapRef.current.updateZone(body["zone_type"], body["zones"])
    }
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData(),
        fetchMapExtent(),
        fetchGeofencingZones()
      ]);

      setLoading(false)
    }

    if (!loading) {
      mapRef.current.closePopup()
      setLoading(true)
    }

    init()
  }, [])

  useLoop(loadData, 10000)

  const onSearch = async (value) => {    
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: minimize ? "100%" : "50%", marginBottom: "5px" }}>
        <Col xs={12} style={{ height: "100%", overflow: "hidden", paddingLeft: 0, paddingRight: 0}}>
          <MapDisplay focus={focus} ref={mapRef}/>
        </Col>
      </Row>

      {minimize ? (
        <Row style={{ marginTop: "5px", display: minimize ? "block" : "none" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
            <IconButtonComponent onClick={() => setMinimize(false)}>
              <TbArrowsMaximize size={20}/>
            </IconButtonComponent>
          </div>
        </Row>
      ) : (
        <Row style={{ height: "50%", marginTop: "5px", display: minimize ? "none" : "block" }}>
          <Col xs={12} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
              <DataTable
                data={data} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchWorker")}
                columns={{
                  "online": { "label": t("smartwatch_online"), "sorting": false, "nowrap": true },
                  "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                  "battery_level": { "label": t("smartwatch_betteryLevel"), "sorting": false, "nowrap": true },
                  "body_temp": { "label": t("smartwatch_bodyTemp"), "sorting": false, "nowrap": true },
                  "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                  "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true },
                  "last_update": { "label": t("smartwatch_lastUpdate"), "sorting": false, "nowrap": true } 
                }}
                renderActions={(row) => { return [
                  {icon: <MdCenterFocusStrong size={20}/>, text: "Locate Worker", onClick: function(row) { focus(row["device_id"], true) }},
                ]}}
                appActions={() => { return [
                  {icon: <TbArrowsMinimize size={20}/>, text: t("general_add"), onClick: function() { setMinimize(true) }}
                ]}}
              />
            </Paper>
          </Col>
        </Row>
      )}
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchOutdoor
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom'
import { Box, Divider, Stack } from '@mui/material';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaCamera } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";

import { GoAlertFill, GoLog } from "react-icons/go";
import { FaTools, FaLock, FaUsers } from "react-icons/fa";
import { MdOutlineAir } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";
import { IoMdWatch } from "react-icons/io";
import { GrUserWorker } from "react-icons/gr";
import { TbCarCrane } from "react-icons/tb";
import { RiLiveFill } from "react-icons/ri";

import useWindowSize from 'Shared/Hooks/useWindowSize';
import useAuth from 'Shared/Hooks/useAuth';

const MenuTab = ({ open, setOpen, collapse, icon, route, label, subMenu }) => {
  const { organization, project } = useParams()

  const onMenuClick = () => {
    if (open === route) {
      setOpen()
    }
    else {
      setOpen(route)
    }
  }

  return (<>
    {subMenu ? (
      <SubMenu style={{ justifyContent: "center" }} label={!collapse && label} icon={icon} open={open === route} onOpenChange={onMenuClick}>
        {subMenu.map((i) => <>
          {(i != null) && 
            <MenuItem style={collapse ? { zIndex: 1000 } : { marginLeft: "10px", marginRight: "10px", backgroundColor: "#DCDCDC" }} component={<Link to={`/portal/${organization}/${project}/${route}/${i["key"]}`}/>} onClick={() => setOpen()}>
              {i["text"]} 
            </MenuItem> 
          }
        </>)}
      </SubMenu>
    ) : (
      <MenuItem  style={{ justifyContent: "center" }} icon={icon} component={<Link to={`/portal/${organization}/${project}/${route}`}/>}>
        {!collapse && label}
      </MenuItem>
    )}
  </>)
}

const DrawerMenu = ({ collapse, setCollapse, appbarHeight, drawerWidth, projectInfo }) => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()
  const { role } = useAuth()

  const [open, setOpen] = useState()

  const tabs = {
    "cameras": { "text": t("module_cameras"), "icon": <FaCamera size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_cameras_list")},
      (["admin"].includes(role)) ? {"key": "playback", "text": t("module_cameras_playback")} : null
    ]},
    "cameras2": { "text": t("module_cameras"), "icon": <FaCamera size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_cameras_list")},
      (["admin"].includes(role)) ? {"key": "playback", "text": t("module_cameras_playback")} : null
    ]},
    "proximity": { "text": t("module_proximity"), "icon": <TbCarCrane size={25}/>, "subMenu": [
      // {"key": "list", "text": t("module_proximity_list")},
    ]},
    "permit_to_work": { "text": t("module_permitToWork"), "icon": <TbCertificate size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_permitToWork_list")},
      {"key": "templates", "text": t("module_permitToWork_templates")},
    ]},
    "assets_tracking": { "text": t("module_assetsTracking"), "icon": <FaTools size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_assetsTracking_list")},
      {"key": "types", "text": t("module_assetsTracking_types")},
      {"key": "certificates", "text": t("module_assetsTracking_certificates")},
    ]},
    "gas": { "text": t("module_gas"), "icon": <MdOutlineAir size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_gas_data")},
    ]},
    "confined_space_smartwatch": { "text": t("module_confinedSpaceSmartwatch"), "icon": <IoMdWatch size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_confinedSpaceSmartwatch_list")},
      {"key": "devices", "text": t("module_confinedSpaceSmartwatch_devices")},
      {"key": "zones", "text": t("module_confinedSpaceSmartwatch_zones")},
      (["pm", "so", "admin"].includes(role)) ? {"key": "history", "text": t("module_confinedSpaceSmartwatch_history")} : null
    ]},
    "outdoor_smartwatch": { "text": t("module_outdoorSmartwatch"), "icon": <IoMdWatch size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_outdoorSmartwatch_list")},
      {"key": "devices", "text": t("module_outdoorSmartwatch_devices")},
      (["admin"].includes(role)) ? {"key": "geofencing", "text": t("module_outdoorSmartwatch_geofencing")} : null,
      (["pm", "so", "admin"].includes(role)) ? {"key": "history", "text": t("module_outdoorSmartwatch_history")} : null
    ]},
    "vr_training": { "text": t("module_vrTraining"), "icon": <MdEditDocument size={25}/>, "subMenu": [
      {"key": "records", "text": t("module_vrTraining_records")},
    ]},
    "smartlock": { "text": t("module_smartlock"), "icon": <FaLock size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_smartlock_list")},
    ]},
  }

  return (
    <Box sx={{ display: 'block' }}>        
      <Stack justifyContent="space-between" direction="column" spacing={0} style={{ height: windowSize["height"]-appbarHeight }}>
        <Box style={{ flexGrow: 1, overflow: "auto" }} className="scollbar">
          <Sidebar collapsed={collapse} width={drawerWidth["lg"]} collapsedWidth={drawerWidth["xs"]}>
            <Menu closeOnClick style={{ backgroundColor: "white" }}>
              {projectInfo && <>
                <MenuTab route="alert" icon={<GoAlertFill size={25} />} label={t("module_alerts")}/>
                <MenuTab route="live" icon={<RiLiveFill size={25} />} label={t("module_live")}/>

                {Object.entries(tabs).map(([k, v]) => <>
                  {(projectInfo["modules"].filter((i) => i["module"] === k && i["enabled"] == 1).length > 0) &&
                    <MenuTab
                      route={k} icon={v["icon"]} label={v["text"]} subMenu={v["subMenu"]}
                      open={open} setOpen={setOpen} collapse={collapse}
                    />
                  }
                </>)}

                <MenuTab route="workers" icon={<GrUserWorker size={25} />} label={t("module_workers")}/>
                
                {role === "admin" && <>
                  <MenuTab route="users" icon={<FaUsers size={25} />} label={t("module_users")}/>
                  <MenuTab route="system_log" icon={<GoLog size={25} />} label={t("module_systemLog")}/>
                </>}
              </>}
            </Menu>
          </Sidebar>
        </Box>

        <Divider sx={{ bgcolor: "secondary.light" }}/>

        <Box>
          <Sidebar collapsed={collapse} width={drawerWidth["lg"]} collapsedWidth={drawerWidth["xs"]}>
            <Menu style={{ backgroundColor: "white" }}>
              {collapse ? (
                <MenuItem icon={<FaAngleDoubleRight />} onClick={() => setCollapse(false)} />
              ) : (
                <MenuItem icon={<FaAngleDoubleLeft />} onClick={() => setCollapse(true)} />
              )}
            </Menu>
          </Sidebar>
        </Box>
      </Stack>
    </Box>
  )
}

export default DrawerMenu

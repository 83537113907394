import React from 'react'

import PopupModal from 'Shared/Components/PopupModal'
import { apiUrl } from 'Shared/utils'

const PlaybackModal = ({ type, record_id, toggle }) => {

  return (
    <PopupModal isOpen={record_id != null} height="80%" width="80%" close toggle={toggle}>
      <div style={{ width: "100%", height: "100%", padding: "5px", overflowX: "hidden", overflowY: "hidden" }}>
        {(type == "video" && record_id != null) &&
          <iframe src={`${apiUrl}/templates/video.html?record_id=${record_id}`} style={{ width: "100%", height: "100%" }}/>
        }

        {(type == "snapshot" && record_id != null) && 
          <iframe src={`${apiUrl}/templates/snapshot.html?record_id=${record_id}`} style={{ width: "100%", height: "100%" }}/>
        }
      </div>
    </PopupModal>
  )
}

export default PlaybackModal

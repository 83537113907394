import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, TreeSelect, DatePicker, Checkbox, Radio } from 'antd';

import { HiSignal, HiSignalSlash } from "react-icons/hi2";
import { IoIosAlert } from "react-icons/io";
import { MdOutlinePending } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineTimerOff } from "react-icons/md";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useLoop from 'Shared/Hooks/useLoop';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import moment from 'moment';
import dayjs from 'dayjs';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`Count: ${payload.value}`}
      </text>
    </g>
  );
};

const AlertSummary = () => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const { projectInfo } = useOutletContext()

  const [date, setDate] = useState([new Date(), new Date()]);
  const [reportAlert, setReporAlert] = useState()
  const [projectModules, setProjectModules] = useState()
  const [alertExist, setAlertExist] = useState(false)
  const [mode, setMode] = useState("0");
  const [activeIndex, setActiveIndex] = useState(null)
  const [loading, setLoading] = useState(true);

  const getReportAlert = async (modules, dateRange) => {
    const getCount = (type) => {
      const item = body.filter((i) => i["type"] === type)

      if (item.length === 0) {
        return 0
      }

      if (alertExist === false) {
        setAlertExist(true)
      }

      return item[0]["count"]
    }

    setLoading(true)

    const body = await send({
      method: "GET",
      url: `${apiUrl}/dashboard/alert_summary`,
      params: {
        "start_date": moment(dateRange[0]).format("YYYY-MM-DD"),
        "end_date": moment(dateRange[1]).format("YYYY-MM-DD")
      },
      returnType: "json"
    })

    const data = []

    if (modules.includes("outdoor_smartwatch") || modules.includes("confined_space_smartwatch")) {
      data.push({ "name": t("module_smartwatch"), "value": getCount("smartwatch") })
    }

    if (modules.includes("cameras")) {
      data.push({ "name": t("module_aiDetection"), "value": getCount("ai_detection") })
    }

    if (modules.includes("proximity")) {
      data.push({ "name": t("module_proximity"), "value": getCount("proximity") })
    }

    setReporAlert(data)

    setLoading(false)
  }

  useEffect(() => {
    const init = async (modules) => {
      setAlertExist(false)
      setProjectModules(modules)

      await Promise.all([
        getReportAlert(modules, date),
      ]);

      setLoading(false)
    }

    if (projectInfo != null) {
      const modules = projectInfo["modules"].filter((i) => i["enabled"] === 1).map((i) => i["module"])

      init(modules)
    }
  }, [projectInfo])

  const handleReportDateChange = async (value) => {
    const d = [new Date(value[0]), new Date(value[1])]

    setDate(d)
    await getReportAlert(projectModules, d)
  }

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };

  const onModeChange = async (value) => {
    let d = null

    switch (value) {
      case "0":
        d = [new Date(moment()), new Date(moment())]
        break
      case "1":
        d = [new Date(moment().subtract(7, 'day')), new Date(moment())]
        break
      case "2":
        d = [new Date(moment().subtract(1, 'month')), new Date(moment())]
        break
      default:
        break
    }

    if (d) {
      setDate(d)
      await getReportAlert(projectModules, d)
    }

    setMode(value)
  }
    
  return (<>
    <div className="mainContainer">
      <div style={{ width: "100%", height: "100%", overflow: "auto", scrollbarWidth: 'none' }}>
        {(projectInfo != null && loading === false) &&
          <Stack direction="column" spacing={2}>
            {reportAlert && 
              <Paper elevation={3} style={{ padding: "10px", width: "100%", height: alertExist ? "400px" : "150px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }}>
                  <div>{t("alert_alertSummary")}</div>

                  <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
                    <Box sx={{ marginRight: { xs: "0px", lg: "3px" }, marginBottom: { xs: "3px", lg: "0px" } }}>
                      <Radio.Group style={{ width: "350px" }}
                        block optionType="button" buttonStyle="solid" defaultValue="0"
                        value={mode} onChange={(e) => onModeChange(e.target.value)}
                        options={[
                          { label: '1 Day', value: '0' },
                          { label: '7 Days', value: '1' },
                          { label: '1 Month ', value: '2' },
                          { label: 'Custom', value: '3' },
                        ]}
                      />
                    </Box>

                    <Box sx={{ marginLeft: { xs: "0px", lg: "3px" }, marginTop: { xs: "3px", lg: "0px" } }}>
                      <DatePicker.RangePicker
                        style={{ width: "100%" }} maxDate={dayjs(moment().format("YYYY-MM-DD"))} value={[dayjs(date[0]), dayjs(date[1])]}
                        popupStyle={{ zIndex: 1500 }}
                        onChange={(value) => handleReportDateChange(value)}
                        disabled={mode != "3"}
                      />
                    </Box>
                  </Box>
                </div>
                
                {alertExist ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width="100%" height="100%">
                      <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={reportAlert}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={onPieEnter}
                      >
                        {(projectModules.includes("outdoor_smartwatch") || projectModules.includes("confined_space_smartwatch")) && <>
                          <Cell key="smartwatch" fill="#FF9999" />
                        </>}

                        {projectModules.includes("cameras") && <>
                          <Cell key="ai_detection" fill="#99FF99" />
                        </>}
                        
                        {projectModules.includes("proximity") && <>
                          <Cell key="proximity" fill="#9999FF" />
                        </>}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" , justifyContent: "center"}}>
                    <div>{t("alert_noRecord")}</div>
                  </div>
                )}
              </Paper>
            }
          </Stack>
        }
      </div>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default AlertSummary

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Form, TreeSelect, DatePicker, Checkbox, Switch, Slider } from 'antd';
import { Button, ButtonGroup } from 'react-bootstrap'
import { Box, Tabs, Tab } from '@mui/material';

import AIPPE from './Forms/AIPPE';
import AIFire from './Forms/AIFire';

import LoadingScreen from 'Shared/Components/LoadingScreen';
import { customizeRequiredMark, NumberInput, SliderField } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';

const AIConfigModal = ({ camera_id, toggle, setLoading }) => {
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [currentTab, setCurrentTab] = useState();

  return (<>
    <div style={{ color: "red", backgroundColor: "pink", padding: "10px" }}>Under development</div>

    <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
      <Tab label="PPE" value="ppe"/>
      <Tab label="Fire" value="fire"/>
    </Tabs>

    <Box style={{ flexGrow: 1, overflow: "auto" }} className='scollbar'>
      {currentTab === "ppe" && 
        <AIPPE camera_id={camera_id} toggle={toggle} setLoading={setLoading} />
      }

      {currentTab === "fire" && 
        <AIFire camera_id={camera_id} toggle={toggle} setLoading={setLoading} />
      }
    </Box>
  </>)
}

export default AIConfigModal

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Form, TreeSelect, DatePicker, Checkbox, Switch, Slider } from 'antd';
import { Button, ButtonGroup } from 'react-bootstrap'

import LoadingScreen from 'Shared/Components/LoadingScreen';
import { customizeRequiredMark, NumberInput, SliderField } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import RoiDrawModal from '../RoiDrawModal';

const AIFire = ({ camera_id, toggle, setLoading }) => {
  const { t } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();

  const [roi, setRoi] = useState({
    "area": null,
    "snapshot": null
  });

  const [moduleNotFound, setModuleNotFound] = useState(false);
  const [roiModalOpen, setRoiModalOpen] = useState(false);

  const loadConfig = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/ai_detection/config/fire/${camera_id}`,
      returnType: "json"
    })

    if (body == null) {
      setModuleNotFound(true)
      return
    }

    form.setFieldsValue({
      "detection_enabled": body["enabled"],
      "fire_enabled": body["fire_enabled"],
      "fire_conf": body["fire_conf"],
      "smoke_enabled": body["smoke_enabled"],
      "smoke_conf": body["smoke_conf"],
      "recording_duration": body["recording_duration"],
      "cool_down_time": body["cool_down_time"],
      "push_alert": body["push_alert"]
    })

    setRoi({
      "area": body["roi"],
      "snapshot": null
    })
  }

  useEffect(() => {
    setLoading(true)

    const init = async () => {
      await Promise.all([
        loadConfig()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/ai_detection/config/fire/${camera_id}`,
      contentType: "json",
      body: {
        "enabled": values["detection_enabled"],
        "fire_enabled": values["fire_enabled"],
        "fire_conf": values["fire_enabled"] ? values["fire_conf"] : 0,
        "smoke_enabled": values["smoke_enabled"],
        "smoke_conf": values["smoke_enabled"] ? values["smoke_conf"] : 0,
        "recording_duration": values["recording_duration"],
        "cool_down_time": values["cool_down_time"],
        "push_alert": values["push_alert"],
        "roi": roi["snapshot"] ? roi["area"] : null
      }
    })

    if (res != null) {
      showSuccess(t("general_success"))
      toggle()
    }

    setLoading(false)
  }

  const detection_enabled = Form.useWatch('detection_enabled', { form, preserve: true });
  const fire_enabled = Form.useWatch('fire_enabled', { form, preserve: true });
  const smoke_enabled = Form.useWatch('smoke_enabled', { form, preserve: true });

  return (<>
    {moduleNotFound ? (
      <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div><b>Module not found</b></div>
      </div>
    ) : (<>
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>
        <Form.Item name="detection_enabled" label="Detection Enabled">
          <Switch />
        </Form.Item>

        {detection_enabled && <>
          <Form.Item name="fire_enabled" label="Fire Detection Enabled">
            <Switch />
          </Form.Item>

          {fire_enabled && 
            <div style={{ display: "flex", flexDirection:"row" }}>
              <div style={{ width: "80%" }}>
                <SliderField field_id="fire_conf" field_name="Fire Detection Confidence" required props={{ "min": 0, "max": 1, "step": 0.01 }} /> 
              </div>

              <div style={{ width: "20%", paddingLeft: "10px" }}>
                <NumberInput field_id="fire_conf" field_name=" " props={{ "min": 0, "max": 1, "step": 0.01, "precision": 2 }}/>
              </div>
            </div>
          }

          <Form.Item name="smoke_enabled" label="Smoke Detection Enabled">
            <Switch />
          </Form.Item>

          {smoke_enabled && 
            <div style={{ display: "flex", flexDirection:"row" }}>
              <div style={{ width: "80%" }}>
                <SliderField field_id="smoke_conf" field_name="Smoke Detection Confidence" required props={{ "min": 0, "max": 1, "step": 0.01 }} /> 
              </div>

              <div style={{ width: "20%", paddingLeft: "10px" }}>
                <NumberInput field_id="smoke_conf" field_name=" " props={{ "min": 0, "max": 1, "step": 0.01, "precision": 2 }}/>
              </div>
            </div>
          }

          <NumberInput field_id="recording_duration" field_name="Recording Duration (Seconds)" required props={{ "min": 3, "max": 10 }}/>

          <NumberInput field_id="cool_down_time" field_name="Cool Down Time (Seconds)" required props={{ "min": 5, "max": 300 }}/>

          <Form.Item name="push_alert" label="Push Alert Enabled">
            <Switch />
          </Form.Item>

          <Form.Item>
            <Button onClick={() => setRoiModalOpen(true)}>
              {t("Redraw ROI")}
            </Button>
          </Form.Item>
        </>}

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>

      {roiModalOpen && 
        <RoiDrawModal camera_id={camera_id} toggle={() => setRoiModalOpen()} roi={roi["area"]} onRoiChange={(value) => setRoi(value)} setLoading={setLoading}/>
      }
    </>)}
  </>)
}

export default AIFire

import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";

import Portal from "Portal/Main/Portal";
import Dashboard from "Portal/Main/components/Dashboard/Dashboard";

import Alert from "Portal/Alert/Alert";
import Live from "Portal/Live/Live";
import Workers from "Portal/Workers/Workers";
import ProjectSystemLog from "Portal/ProjectSystemLog/ProjectSystemLog";

import PermitToWorkList from "Portal/PermitToWork/PermitToWorkList";
import PermitToWorkDetail from "Portal/PermitToWork/PermitToWorkDetail";
import PermitToWorkTemplates from "Portal/PermitToWork/PermitToWorkTemplates";

import AssetList from "Portal/AssetsTracking/AssetList";
import AssetTypes from "Portal/AssetsTracking/AssetTypes";
import AssetCertificates from "Portal/AssetsTracking/AssetCertificates";

import GasData from "Portal/Gas/GasData";
import GasDataHistory from "Portal/Gas/GasDataHistory";

import VRTrainingRecord from "Portal/VRTraining/VRTrainingRecord";

import CameraList from "Portal/Cameras/CameraList";
import CameraPlayback from "Portal/Cameras/CameraPlayback";

import SmartwatchConfinedSpace from "Portal/Smartwatch/ConfinedSpace/SmartwatchConfinedSpace";
import SmartwatchConfinedSpaceDevices from "Portal/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceDevices";
import SmartwatchConfinedSpaceZones from "Portal/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceZones";
import SmartwatchConfinedSpaceHistory from "Portal/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceHistory";

import SmartwatchOutdoor from "Portal/Smartwatch/Outdoor/SmartwatchOutdoor";
import SmartwatchOutdoorDevices from "Portal/Smartwatch/Outdoor/SmartwatchOutdoorDevices";
import SmartwatchOutdoorGeofencing from "Portal/Smartwatch/Outdoor/SmartwatchOutdoorGeofencing";
import SmartwatchOutdoorHistory from "Portal/Smartwatch/Outdoor/SmartwatchOutdoorHistory";

import ProximityList from "Portal/Proximity/ProximityList";

import SmartlockList from "Portal/Smartlock/SmartlockList";

import AdminConsole from "AdminConsole/Main/AdminConsole";

import Users from "AdminConsole/Users/Users";
import Projects from "AdminConsole/Projects/Projects";
import ProjectUsers from "Portal/ProjectUsers/ProjectUsers";
import SystemLog from "AdminConsole/SystemLog/SystemLog";

import { NotifyToast } from 'Shared/Components/NotifyToast'
import { webUrl } from "Shared/utils";

function App() {
  const { error, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { t } = useTranslation()

  const [start, setStart] = useState(false)

  useEffect(() => {
    const getToken = async () => {
      try {
        const res = await getAccessTokenSilently({
          ignoreCache: true,
          scope: "openid offline_access",
          grant: "refresh_token",
          detailedResponse: true,
        })

        sessionStorage.setItem('accessToken', res.access_token)

        if (!start) {
          setStart(true)
        }

        setTimeout(async function()  {
          await getToken()
        }, (res.expires_in - 100)*1000);
      }
      catch (e){
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }

    if (!isLoading) {
      if (!error) {
        if (isAuthenticated) {
          getToken()
        }
        else {
          loginWithRedirect({ appState: { returnTo: window.location.pathname } })
        }
      }
      else {
        const errorMsg = new String(error).toString()

        switch (errorMsg) {
          case "Error: Please verify your email before logging in.":
            alert(t("loginError_unverified"))
            break
          case "Error: client requires organization membership, but user does not belong to any organization":
            alert(t("loginError_noOrg"))
            break
          default:
            alert(errorMsg)
            break
        }

        logout({ logoutParams: { returnTo: webUrl } })
      }
    }
  }, [isLoading, isAuthenticated])

  return (<>
    {start &&
      <Routes>
        <Route path='/portal/:organization?/:project?' element={<Portal />}>
          <Route index element={<Dashboard />} />

          <Route exact path="alert" element={<Alert />} />
          <Route exact path="live" element={<Live />} />
          <Route exact path="workers" element={<Workers />} />
          <Route exact path="users" element={<ProjectUsers />} />
          <Route exact path="system_log" element={<ProjectSystemLog />} />

          <Route exact path="cameras/list" element={<CameraList />} />
          <Route exact path="cameras/playback" element={<CameraPlayback />} />

          <Route exact path="proximity/list" element={<ProximityList />} />

          <Route exact path="permit_to_work/list" element={<PermitToWorkList />} />
          <Route path="permit_to_work/list/:template_id/:form_id?" element={<PermitToWorkDetail />} />
          <Route exact path="permit_to_work/templates" element={<PermitToWorkTemplates />} />

          <Route exact path="assets_tracking/list" element={<AssetList />} />
          <Route exact path="assets_tracking/types" element={<AssetTypes />} />
          <Route exact path="assets_tracking/certificates" element={<AssetCertificates />} />

          <Route exact path="gas/list" element={<GasData />} />
          <Route exact path="gas/list/:device_id" element={<GasDataHistory />} />

          <Route exact path="vr_training/records" element={<VRTrainingRecord />} />
        
          <Route exact path="confined_space_smartwatch/list" element={<SmartwatchConfinedSpace />} />
          <Route exact path="confined_space_smartwatch/devices" element={<SmartwatchConfinedSpaceDevices />} />
          <Route exact path="confined_space_smartwatch/zones" element={<SmartwatchConfinedSpaceZones />} />
          <Route exact path="confined_space_smartwatch/history" element={<SmartwatchConfinedSpaceHistory />} />

          <Route exact path="outdoor_smartwatch/list" element={<SmartwatchOutdoor />} />
          <Route exact path="outdoor_smartwatch/devices" element={<SmartwatchOutdoorDevices />} />
          <Route exact path="outdoor_smartwatch/geofencing" element={<SmartwatchOutdoorGeofencing />} />
          <Route exact path="outdoor_smartwatch/history" element={<SmartwatchOutdoorHistory />} />

          <Route exact path="smartlock/list" element={<SmartlockList />} />
        </Route>

        <Route path='/admin_console/:organization?' element={<AdminConsole />}>
          <Route index element={<Navigate to="./users" replace />} />

          <Route exact path="users" element={<Users />} />
          <Route exact path="projects" element={<Projects />} />
          <Route exact path="system_log" element={<SystemLog />} />
        </Route>

        <Route index element={<Navigate to="./portal" replace />} />
      </Routes>
    }

    <div>{<NotifyToast/>}</div>
  </>)
}

export default App;
